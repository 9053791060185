// frontend/src/hooks/useSavePageContent.js
import { useContext, useCallback } from 'react';
import axios from 'axios';
import { PageContentContext } from '../contexts/PageContentContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { usePageContentUtils } from './usePageContentUtils';
import PageContainer from '../components/PageContainer';

const generateCSSContent = (designTokens, editedTokens) => {
  let cssContent = '';
  const mediaQueries = {
    small: '@media (max-width: 600px)',
    medium: '@media (min-width: 601px) and (max-width: 1024px)',
    large: '@media (min-width: 1025px)',
  };

  // Create a set of all component keys (for rows, columns, and components)
  const allKeys = new Set([
    ...Object.keys(designTokens || {}),
    ...Object.keys(editedTokens || {})
  ]);

  for (const componentId of allKeys) {
    // Merge the tokens for this component from designTokens and editedTokens
    const designForComponent = designTokens[componentId] || {};
    const editedForComponent = editedTokens[componentId] || {};
    // Get union of viewport sizes for this component
    const viewports = new Set([
      ...Object.keys(designForComponent),
      ...Object.keys(editedForComponent)
    ]);
    for (const viewportSize of viewports) {
      // Merge tokens for the viewport
      const tokens = {
        ...((designForComponent[viewportSize]) || {}),
        ...((editedForComponent[viewportSize]) || {})
      };
      let cssRules = '';
      for (const [property, value] of Object.entries(tokens)) {
        // Skip empty color values
        if ((property.toLowerCase().includes('color')) && (!value || value.toString().trim() === '')) {
          continue;
        }
        if (!property || typeof property !== 'string') {
          console.warn(`Invalid property name: "${property}" for component "${componentId}"`);
          continue;
        }
        if (typeof value !== 'string' && typeof value !== 'number') {
          console.warn(`Invalid value type for property "${property}" in component "${componentId}":`, value);
          continue;
        }
        cssRules += `  ${property}: ${value};\n`;
      }
      if (cssRules) {
        const mediaQuery = mediaQueries[viewportSize] || `@media (${viewportSize})`;
        cssContent += `${mediaQuery} {\n  #${componentId} {\n${cssRules}  }\n}\n`;
      }
    }
  }
  if (!cssContent) {
    console.warn('Generated CSS content is empty.');
    return '';
  }
  return cssContent;
};


const useSavePageContent = (
  token, 
  pages, 
  setPages,
  activePage,
  components, 
  designTokens, 
  selectedComponentInfo, 
  setSelectedComponentInfo, 
  editedTokens, 
  setEditedTokens,
  selectedComponentProps
) => {
  const { rows } = useContext(PageContentContext);
  const { nestedComponents } = useContext(NestedComponentsContext);
  const { convertFrontendStructureToBackendSchema } = usePageContentUtils(components, selectedComponentProps);

  const savePageContent = useCallback(async (pageIdToSave, pageToSave) => {
    console.log('Attempting to save page:', pageIdToSave);
    if (!token) {
      console.error('No token available.');
      return;
    }
    if (!pageToSave) {
      console.error('Page to save is not defined.');
      return;
    }

    const pageContentForBackend = convertFrontendStructureToBackendSchema(rows, nestedComponents);
    console.log('Converted page content for backend:', pageContentForBackend);

    try {
      const saveResponse = await axios.put(
        'https://www.webbify.io/pageContent',
        {
          pageId: pageIdToSave,
          pageName: pageToSave.pageName,
          content: { rows: pageContentForBackend },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Save Response:', saveResponse.data);

      const cssContent = generateCSSContent(designTokens, editedTokens);
      if (!cssContent) {
        console.warn('Generated CSS content is empty.');
      }
      console.log('Sending PUT request to /pageCSS with:', {
        pageId: pageIdToSave,
        pageName: pageToSave.pageName,
        cssContent,
      });
      const cssResponse = await axios.put(
        'https://www.webbify.io/pageCSS',
        {
          pageId: pageIdToSave,
          pageName: pageToSave.pageName,
          cssContent,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('CSS Save Response:', cssResponse.data);

      const updatedPages = [...pages];
      const pageIndex = updatedPages.findIndex(page => page.pageId === pageIdToSave);
      if (pageIndex !== -1) {
        updatedPages[pageIndex] = {
          ...updatedPages[pageIndex],
          content: (
            <PageContainer 
              components={components}
              designTokens={designTokens}
              rows={rows}
              selectedComponentInfo={selectedComponentInfo} 
              setSelectedComponentInfo={setSelectedComponentInfo}
              editedTokens={editedTokens} 
              setEditedTokens={setEditedTokens}
              nestedComponents={nestedComponents}
            />
          ),
        };
        setPages(updatedPages);
      }
    } catch (error) {
      console.error('Error saving page content:', error);
    }
  }, [
    token, pages, setPages, activePage, components, designTokens, 
    selectedComponentInfo, setSelectedComponentInfo, editedTokens, 
    setEditedTokens, nestedComponents, rows
  ]);

  return savePageContent;
};

export default useSavePageContent;
