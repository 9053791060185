// frontend/src/utils/reconstructFrontendStructureFromBackendSchema.js

import { components as componentImports } from '../components/ComponentImports';
import { loadComponentFromS3, loadUserComponentFromS3, registerDynamicComponent } from '../components/DynamicComponentLoader';
import { v4 as uuidv4 } from 'uuid';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext'; // <--- If you'd like to keep this import

/**
 * Reconstructs the main page structure (rows/columns/components) from the
 * backend data. Also reconstructs nested container components to indefinite depth.
 * 
 * @param {Array}  content                  - The array of row objects from the backend
 * @param {Object} components               - Map/object of your local components
 * @param {Object} nestedComponentsContext  - Typically an instance of NestedComponentsContext (optional if you prefer)
 * @param {String} token                    - Auth token if you load from S3
 * 
 * @returns {Promise<Object>}  An object with:
 *  - rows: top-level array for your page builder
 *  - nestedComponents: a map of { containerId: { columnId: [components...] } }
 */
export const reconstructFrontendStructureFromBackendSchema = async (
  content,
  components,
  nestedComponentsContext,
  token
) => {
  console.log(
    'reconstructFrontendStructureFromBackendSchema - Reconstructing Content:',
    content
  );
  if (!Array.isArray(content)) {
    console.error('Invalid content structure:', content);
    return [];
  }

  // We’ll collect all nested sub-components in this map: { containerId: { columnId: [...] } }
  let nestedComponentsMap = {};

  // Rebuild each row
  const reconstructedRows = await Promise.all(
    content.map(async (rowData) => {
      console.log(
        'reconstructFrontendStructureFromBackendSchema - Row Data:',
        rowData
      );
      if (!rowData.columns || !Array.isArray(rowData.columns)) {
        console.error('Invalid rowData structure:', rowData);
        return null;
      }

      // For each column in the row:
      const columnData = await Promise.all(
        rowData.columns.map(async (column) => {
          // Rebuild each component in the column
          const componentData = await Promise.all(
            column.components.map(async (componentInfo) => {
              let Component;

              // If the component source is either 's3' or 's3-user', call the appropriate loader.
              if (
                (componentInfo.source === 's3' || componentInfo.source === 's3-user') &&
                componentInfo.s3ComponentName
              ) {
                if (componentInfo.source === 's3') {
                  Component = await loadComponentFromS3(
                    componentInfo.s3ComponentName,
                    token
                  );
                } else {
                  Component = await loadUserComponentFromS3(
                    componentInfo.s3ComponentName,
                    token
                  );
                }
              } else if (componentInfo.source === 'local') {
                if (componentImports[componentInfo.name]) {
                  if (typeof componentImports[componentInfo.name] === 'function') {
                    // Dynamically import
                    const module = await componentImports[componentInfo.name]();
                    Component = module.default || module;
                  } else if (componentImports[componentInfo.name]?.component) {
                    // Possibly a code-split object with `.component`
                    Component = componentImports[componentInfo.name].component;
                  } else {
                    console.error(
                      `Component "${componentInfo.name}" is not a function or loaded properly.`
                    );
                    return null;
                  }
                } else {
                  console.error(
                    `Local component "${componentInfo.name}" not found.`
                  );
                  return null;
                }
              } else if (!componentInfo.source) {
                console.warn(
                  `Component "${componentInfo.name}" has no source. Defaulting to 'local'...`
                );
                if (componentImports[componentInfo.name]) {
                  const module = await componentImports[componentInfo.name]();
                  Component = module.default || module;
                } else {
                  console.error(
                    `Local component "${componentInfo.name}" not found.`
                  );
                  return null;
                }
              } else {
                console.error(`Unknown component source: "${componentInfo.source}"`);
                return null;
              }

              const componentId = componentInfo.id || uuidv4();

              // 2) If this component has nestedComponents, reconstruct them recursively
              if (
                Array.isArray(componentInfo.nestedComponents) &&
                componentInfo.nestedComponents.length > 0
              ) {
                // Rebuild the nested sub-components (which can themselves be containers)
                const reconstructedNestedComponents =
                  await reconstructNestedComponents(
                    componentInfo.nestedComponents,
                    token
                  );

                // Merge them into the top-level map
                nestedComponentsMap[componentId] = reconstructedNestedComponents;
              }

              // Return the top-level component object for row => column => components
              return Component
                ? {
                    id: componentId,
                    component: Component,
                    name: componentInfo.name,
                    source: componentInfo.source,
                    s3ComponentName: componentInfo.s3ComponentName,
                    props: componentInfo.props || {},
                    tokens: componentInfo.tokens || {},
                  }
                : null;
            })
          ); // end componentData = ...

          return {
            columnId: column.columnId,
            columnSize: column.columnSize,
            components: componentData.filter((comp) => comp !== null),
          };
        })
      );

      // Return the final row object
      return {
        rowId: rowData.rowId,
        columnIds: rowData.columns.map(col => col.columnId),
        columns: rowData.columns.map((col) => col.columnSize),
        components: columnData.map((col) => col.components),
      };
    })
  );

  // Filter out null rows
  const finalRows = reconstructedRows.filter((row) => row !== null);

  // Return an object containing:
  //   rows: top-level array for your PageBuilder
  //   nestedComponents: the containerId => { columnId: [...] } mapping
  return {
    rows: finalRows,
    nestedComponents: nestedComponentsMap,
  };
};

/**
 * Recursively reconstructs an *array* of nested components for a container.
 * Each item has a { columnId, components: [ ... ] } structure, so we do the same
 * for each child. If a child is itself a container with more nestedComponents,
 * we recursively call ourselves again.
 */
async function reconstructNestedComponents(nestedComponentsData, token) {
  const nestedComponentsResult = {};

  // Each entry in nestedComponentsData is { columnId, components: [ ... ] }
  for (const nestedColumnGroup of nestedComponentsData) {
    const { columnId, components: nestedCompArr } = nestedColumnGroup;
    const reconstructedComponents = [];

    // Build up each sub-component in that column
    if (Array.isArray(nestedCompArr)) {
      for (const nestedInfo of nestedCompArr) {
        let NestedComp;

        // Load local or s3 for the nested component
        if (nestedInfo.source === 's3' && nestedInfo.s3ComponentName) {
          NestedComp = await loadComponentFromS3(nestedInfo.s3ComponentName, token);
        } else if (nestedInfo.source === 's3-user' && nestedInfo.s3ComponentName) {
          NestedComp = await loadUserComponentFromS3(nestedInfo.s3ComponentName, token);
        } else if (nestedInfo.source === 'local') {
          if (componentImports[nestedInfo.name]) {
            if (typeof componentImports[nestedInfo.name] === 'function') {
              const module = await componentImports[nestedInfo.name]();
              NestedComp = module.default || module;
            } else if (componentImports[nestedInfo.name]?.component) {
              NestedComp = componentImports[nestedInfo.name].component;
            } else {
              console.error(
                `Nested: "${nestedInfo.name}" not a function or loaded properly.`
              );
              continue;
            }
          } else {
            console.error(`Local nested component "${nestedInfo.name}" not found.`);
            continue;
          }
        } else if (!nestedInfo.source) {
          console.warn(
            `Nested component "${nestedInfo.name}" has no source. Defaulting to local...`
          );
          if (componentImports[nestedInfo.name]) {
            const module = await componentImports[nestedInfo.name]();
            NestedComp = module.default || module;
          } else {
            console.error(`Local nested component "${nestedInfo.name}" not found.`);
            continue;
          }
        } else {
          console.error(`Unknown nested component source: "${nestedInfo.source}"`);
          continue;
        }

        const nestedId = nestedInfo.id || uuidv4();

        // If this nested child also has nestedComponents (i.e. it’s another container),
        // we call reconstructNestedComponents again (recursively).
        let deeperNestedMap = {};
        if (
          Array.isArray(nestedInfo.nestedComponents) &&
          nestedInfo.nestedComponents.length > 0
        ) {
          deeperNestedMap = await reconstructNestedComponents(
            nestedInfo.nestedComponents,
            token
          );
        }

        // Build the final sub-component object
        reconstructedComponents.push({
          id: nestedId,
          component: NestedComp,
          name: nestedInfo.name,
          source: nestedInfo.source,
          s3ComponentName: nestedInfo.s3ComponentName,
          props: nestedInfo.props || {},
          tokens: nestedInfo.tokens || {},
        });

        // If there were deeper nested children, merge them into the top-level map
        // so we can handle multi-level containers.
        if (Object.keys(deeperNestedMap).length > 0) {
          nestedComponentsResult[nestedId] = deeperNestedMap;
        }
      }
    }

    // After building all sub-components, store them under the correct columnId
    nestedComponentsResult[columnId] = reconstructedComponents;
  }

  return nestedComponentsResult;
}