// frontend/src/utils/rowColumnManager.js
import { generateUniquePageId } from './pageUtils';

// Create a new row with a unique rowId, default one column, and empty tokens.
export const addRow = (rows, setRows) => {
  const newRow = {
    rowId: generateUniquePageId(), // unique id for the row
    columns: [1],                // one column of size 1 by default
    columnIds: [generateUniquePageId()], // one unique column id
    components: [[]],
    rowTokens: {},        // empty row tokens
    columnTokens: [{}],   // one empty column tokens object
  };
  setRows([...rows, newRow]);
};

export const removeRow = (rows, setRows, index) => {
  const updatedRows = [...rows];
  updatedRows.splice(index, 1);
  setRows(updatedRows);
};

export const removeColumn = (rows, setRows, rowIndex, colIndex) => {
  const updatedRows = [...rows];
  updatedRows[rowIndex].columns.splice(colIndex, 1);
  if (updatedRows[rowIndex].columnIds) {
    updatedRows[rowIndex].columnIds.splice(colIndex, 1);
  }
  if (updatedRows[rowIndex].columnTokens) {
    updatedRows[rowIndex].columnTokens.splice(colIndex, 1);
  }
  updatedRows[rowIndex].components.splice(colIndex, 1);
  setRows(updatedRows);
};

export const addComponentToColumn = (rows, setRows, rowIndex, colIndex, newComponent) => {
  const updatedRows = [...rows];
  if (!Array.isArray(updatedRows[rowIndex].components)) {
    updatedRows[rowIndex].components = [];
  }
  if (!Array.isArray(updatedRows[rowIndex].components[colIndex])) {
    updatedRows[rowIndex].components[colIndex] = [];
  }
  updatedRows[rowIndex].components[colIndex].push(newComponent);
  setRows(updatedRows);
};

export const handleColumnChange = (rows, setRows, rowIndex, newColumnCount) => {
  const updatedRows = [...rows];
  const currentCount = updatedRows[rowIndex].columns.length;
  if (newColumnCount > currentCount) {
    const diff = newColumnCount - currentCount;
    for (let i = 0; i < diff; i++) {
      updatedRows[rowIndex].columns.push(1);
      updatedRows[rowIndex].columnIds.push(generateUniquePageId());
      updatedRows[rowIndex].components.push([]);
      if (!updatedRows[rowIndex].columnTokens) {
        updatedRows[rowIndex].columnTokens = [];
      }
      updatedRows[rowIndex].columnTokens.push({});
    }
  } else if (newColumnCount < currentCount) {
    updatedRows[rowIndex].columns = updatedRows[rowIndex].columns.slice(0, newColumnCount);
    updatedRows[rowIndex].columnIds = updatedRows[rowIndex].columnIds.slice(0, newColumnCount);
    updatedRows[rowIndex].components = updatedRows[rowIndex].components.slice(0, newColumnCount);
    if (updatedRows[rowIndex].columnTokens) {
      updatedRows[rowIndex].columnTokens = updatedRows[rowIndex].columnTokens.slice(0, newColumnCount);
    }
  }
  setRows(updatedRows);
};

export const handleColumnDivChange = (rows, setRows, rowIndex, colIndex, newColumnCount) => {
  const updatedRows = [...rows];
  updatedRows[rowIndex].columns[colIndex] = newColumnCount;
  setRows(updatedRows);
};

export const moveRowUp = (rows, setRows, index) => {
  if (index <= 0) return;
  const updatedRows = [...rows];
  [updatedRows[index - 1], updatedRows[index]] = [updatedRows[index], updatedRows[index - 1]];
  setRows(updatedRows);
};

export const moveRowDown = (rows, setRows, index) => {
  if (index >= rows.length - 1) return;
  const updatedRows = [...rows];
  [updatedRows[index], updatedRows[index + 1]] = [updatedRows[index + 1], updatedRows[index]];
  setRows(updatedRows);
};

export const duplicateRow = (rows, setRows, index, nestedComponents, setNestedComponents) => {
  const updatedRows = [...rows];
  const rowToDuplicate = { ...rows[index] };

  // Generate new row and column IDs.
  rowToDuplicate.rowId = generateUniquePageId();
  rowToDuplicate.columnIds = rowToDuplicate.columnIds.map(() => generateUniquePageId());

  // Duplicate tokens as shallow copies.
  rowToDuplicate.rowTokens = { ...rows[index].rowTokens };
  rowToDuplicate.columnTokens = rows[index].columnTokens
    ? rows[index].columnTokens.map(token => ({ ...token }))
    : [];

  // Duplicate components and assign new IDs.
  rowToDuplicate.components = rowToDuplicate.components.map((columnComponents) =>
    columnComponents.map((component) => {
      const newComponentId = generateUniquePageId();
      if (component.name === 'Container' && nestedComponents[component.id]) {
        const duplicatedNestedComponents = {};
        Object.keys(nestedComponents[component.id]).forEach((columnId) => {
          duplicatedNestedComponents[columnId] = nestedComponents[component.id][columnId].map(
            (nestedComponent) => ({
              ...nestedComponent,
              id: generateUniquePageId(),
            })
          );
        });
        setNestedComponents((prev) => ({
          ...prev,
          [newComponentId]: duplicatedNestedComponents,
        }));
      }
      return {
        ...component,
        id: newComponentId,
      };
    })
  );

  updatedRows.splice(index + 1, 0, rowToDuplicate);
  setRows(updatedRows);
};
