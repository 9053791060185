// frontend/src/components/ui_components/Container/Container.jsx
import React, { useContext } from 'react';
import { BuilderContext } from '../../../contexts/BuilderContext';
import { NestedComponentsContext } from '../../../contexts/NestedComponentsContext';
import { TokensContext } from '../../../contexts/TokensContext';
import { ComponentPropsContext } from '../../../contexts/ComponentPropsContext';
import { ViewportContext } from '../../../contexts/ViewportContext';
import useNestedComponentDrop from '../../../hooks/useNestedComponentDrop';
import useComponentDrop from '../../../hooks/useComponentDrop';
import { getComponentProps } from '../../../hooks/getComponentProps';

const Container = ({
  id,
  children,
  handleComponentClick,
  tokens,
  backgroundImage,
  depth = 0, // top-level depth = 0
  ...props
}) => {
  const isBuilder = useContext(BuilderContext);
  const { nestedComponents } = useContext(NestedComponentsContext);
  const { designTokens, editedTokens } = useContext(TokensContext);
  const { selectedComponentProps, updateComponentProps } = useContext(ComponentPropsContext);
  const viewportSize = useContext(ViewportContext);

  const token = localStorage.getItem('token') || '';
  // The nested drop hook now expects to receive a column id via the drop event's dataTransfer
  const handleNestedComponentDrop = useNestedComponentDrop(id, token, designTokens, editedTokens);
  const handleGeneralDrop = useComponentDrop();

  const defaultTokens = {
    flexGrow: '1',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
  };

  const mergedTokens = {
    ...defaultTokens,
    ...tokens,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
    backgroundSize: 'cover',
  };
  if (!mergedTokens.backgroundColor || mergedTokens.backgroundColor === '') {
    mergedTokens.backgroundColor = defaultTokens.backgroundColor;
  }

  // Simple drop prevention
  const allowDrop = (e) => e.preventDefault();

  // onDropHandler now checks if the drop target has a data attribute "data-column-id"
  // and sets that value into the event so that useNestedComponentDrop can use it.
  const onDropHandler = (event) => {
    if (!isBuilder) return;
    console.log(`[Container ${id}][Level ${depth}] onDropHandler triggered`);
    const dropColumnId = event.currentTarget.getAttribute('data-column-id');
    if (dropColumnId) {
      console.log(`[Container ${id}][Level ${depth}] Drop target column id: ${dropColumnId}`);
      event.dataTransfer.setData('columnId', dropColumnId);
    }
    if (
      event.currentTarget.classList.contains('container-component') ||
      event.currentTarget.classList.contains('nested-container')
    ) {
      console.log(`[Container ${id}][Level ${depth}] Delegating to handleNestedComponentDrop`);
      event.stopPropagation();
      handleNestedComponentDrop(event);
    } else {
      console.log(`[Container ${id}][Level ${depth}] Delegating to handleGeneralDrop`);
      handleGeneralDrop(event);
    }
  };

  // Recursive function to render nested children.
  // Each column is wrapped in a drop zone div that carries its column id via a data attribute.
  const renderNestedChildren = (containerId, currentDepth = depth + 1) => {
    if (!nestedComponents[containerId]) return null;
    console.log(
      `[Container ${id}][Level ${depth}] Rendering nested children for container ${containerId} at depth ${currentDepth}`
    );
    return Object.keys(nestedComponents[containerId]).map((columnId) => {
      const compsArray = nestedComponents[containerId][columnId] || [];
      console.log(
        `[Container ${id}][Level ${depth}] Column "${columnId}" at depth ${currentDepth} has ${compsArray.length} component(s)`
      );
      return (
        <div
          key={columnId}
          className="nested-column-dropzone"
          data-column-id={columnId}  // Each dropzone now has its own column id
          onDrop={onDropHandler}
          onDragOver={allowDrop}
        >
          {compsArray.map((child) => {
            if (!child.id) {
              console.error(`[Container ${id}][Level ${currentDepth}] Nested child missing ID:`, child);
              return null;
            }
            const ChildComp = child.component;
            if (!ChildComp) {
              console.error(`[Container ${id}][Level ${currentDepth}] Child component is undefined for:`, child.name);
              return null;
            }
            const nestedTokens = {
              ...designTokens[child.id]?.[viewportSize],
              ...editedTokens[child.id]?.[viewportSize],
              ...child.tokens?.[viewportSize],
            };
            const childProps = {
              ...getComponentProps(ChildComp),
              ...child.props,
              ...selectedComponentProps[child.id],
            };
            if (!selectedComponentProps[child.id]) {
              updateComponentProps(child.id, childProps);
            }
            if (child.name === 'Container') {
              console.log(
                `[Container ${id}][Level ${currentDepth}] Rendering child Container with ID: ${child.id} inside container ${containerId}`
              );
              return (
                <div
                  key={child.id}
                  className="rendered-component nested-container"
                  data-level={currentDepth}
                >
                  <Container
                    id={child.id}
                    {...childProps}
                    tokens={nestedTokens}
                    handleComponentClick={handleComponentClick}
                    depth={currentDepth}
                  >
                    {renderNestedChildren(child.id, currentDepth + 1)}
                  </Container>
                </div>
              );
            }
            console.log(
              `[Container ${id}][Level ${currentDepth}] Rendering child ${child.name} with ID: ${child.id} inside container ${containerId}`
            );
            return (
              <div
                key={child.id}
                className={`rendered-component ${child.name}`}
                data-level={currentDepth}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleComponentClick(child);
                  }}
                  onDrop={onDropHandler}
                  onDragOver={allowDrop}
                  style={{ margin: '0px', position: 'relative' }}
                >
                  <ChildComp
                    id={child.id}
                    {...childProps}
                    tokens={nestedTokens}
                    handleComponentClick={handleComponentClick}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  // In builder mode, render nested children; otherwise, render children as-is.
  const renderedChildren = isBuilder ? renderNestedChildren(id, depth + 1) : children;

  return (
    <div
      style={{ ...mergedTokens, ...props.style, boxSizing: 'border-box', zIndex: 1 }}
      className="container-component"
      id={id}
      data-level={depth}
      onClick={(e) => {
        e.stopPropagation();
        handleComponentClick({ id, name: 'Container' });
      }}
      onDrop={onDropHandler}
      onDragOver={allowDrop}
    >
      {renderedChildren}
    </div>
  );
};

Container.defaultProps = {
  backgroundImage: '',
  seoTitle: '',
  seoDescription: '',
  ariaLabel: 'container',
};

export default Container;
