// frontend/src/components/PageContainer.js 
import React, { useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { components as componentImports } from './ComponentImports';
import { loadComponentFromS3, loadUserComponentFromS3, registerDynamicComponent } from './DynamicComponentLoader'; // S3 components import
import { getComponentProps } from '../hooks/getComponentProps';
import { PageContentContext } from '../contexts/PageContentContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { ComponentPropsContext } from '../contexts/ComponentPropsContext';
import { SelectedComponentContext } from '../contexts/SelectedComponentContext';
import { TemplateContext } from '../contexts/TemplateContext';
import useComponentDrop from '../hooks/useComponentDrop';
import useTemplateDrop from '../hooks/useTemplateDrop';
import { usePageContentUtils } from '../hooks/usePageContentUtils';
import { reconstructFrontendStructureFromBackendSchema } from '../utils/reconstructFrontendStructureFromBackendSchema';
import DivContainer from './ui_components/DivContainer/DivContainer'; 
import Container from './ui_components/Container/Container';
import ContainerControlBar from './ui_components/ContainerControlBar';
import {
  addRow,
  removeRow,
  removeColumn,
  addComponentToColumn,
  handleColumnChange,
  handleColumnDivChange,
  moveRowUp, 
  moveRowDown,
  duplicateRow,
} from '../utils/rowColumnManager';
import { TokensContext } from '../contexts/TokensContext';
import { ViewportContext } from '../contexts/ViewportContext';
import { ArrowUpOutlined, ArrowDownOutlined, CopyOutlined } from '@ant-design/icons';

const PageContainer = ({ 
  components, 
  designTokens, 
  globalTokens,
  fetchDesignTokens, 
  updateDesignTokens,
  nestedComponents: initialNestedComponents,
}) => {
  const [renderedRows, setRenderedRows] = useState([]);
  const { rows, setRows, pageContent, setPageContent } = useContext(PageContentContext);
  const { setSelectedComponentInfo } = useContext(SelectedComponentContext);
  const { selectedComponentProps, updateComponentProps } = useContext(ComponentPropsContext);
  const { nestedComponents, setNestedComponents } = useContext(NestedComponentsContext);
  const { reconstructFrontendStructureFromBackendSchema } = usePageContentUtils(components);
  const handleComponentDrop = useComponentDrop(components, designTokens);
  const { handleTemplateDrop } = useTemplateDrop(components, designTokens);
  const { forceRender, editedTokens } = useContext(TokensContext);
  const viewportSize = useContext(ViewportContext);
  const { openTemplateModal } = useContext(TemplateContext);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const getViewportWidth = () => {
    switch (viewportSize) {
      case 'small':
        return '375px'; // Mobile
      case 'medium':
        return '768px'; // Tablet
      case 'large':
        return '1024px'; // Laptop
      default:
        return viewportSize; // Custom value
    }
  };

  const containerStyle = {
    minWidth: getViewportWidth(),
    //width: getViewportWidth(),
    margin: '0 auto',
  };

  useEffect(() => {
    console.log('PageContainer: Initialize nestedComponents useEffect triggered');
    console.log('Dependencies:', { initialNestedComponents });

    if (initialNestedComponents) {
      setNestedComponents(initialNestedComponents);
      console.log('Set nestedComponents:', initialNestedComponents);
    } else {
      setNestedComponents({});
      console.log('Cleared nestedComponents as none were provided.');
    }
  }, [initialNestedComponents, setNestedComponents]);

  // Update row selection to use token key "row_<rowId>" for the current viewport.
  const handleRowClick = (rowId) => {
    setSelectedComponentInfo({
      type: 'row',
      id: rowId,
      name: `Row ${rowId}`,
      props: {},
      tokens: {
        ...((designTokens[`row_${rowId}`]?.[viewportSize]) || {}),
        ...((editedTokens[`row_${rowId}`]?.[viewportSize]) || {})
      }
    });
    console.log("Row selected:", rowId);
  };

  // Update column selection to use token key "col_<columnId>" for the current viewport.
  const handleColumnClick = (columnId) => {
    setSelectedComponentInfo({
      type: 'column',
      id: columnId,
      name: `Column ${columnId}`,
      props: {},
      tokens: {
        ...((designTokens[`col_${columnId}`]?.[viewportSize]) || {}),
        ...((editedTokens[`col_${columnId}`]?.[viewportSize]) || {})
      }
    });
    console.log("Column selected:", columnId);
  };

  // Utils implementing rowColumnManager functions
  const onAddRow = () => addRow(rows, setRows);
  const onRemoveRow = (index) => removeRow(rows, setRows, index);
  const onRemoveColumn = (rowIndex, colIndex) => removeColumn(rows, setRows, rowIndex, colIndex);
  const onAddComponentToColumn = (rowIndex, colIndex, newComponent) => addComponentToColumn(rows, setRows, rowIndex, colIndex, newComponent);
  const onHandleColumnChange = (rowIndex, newColumnCount) => handleColumnChange(rows, setRows, rowIndex, newColumnCount);
  const onHandleColumnDivChange = (rowIndex, colIndex, newColumnCount) => handleColumnDivChange(rows, setRows, rowIndex, colIndex, newColumnCount);
  const onMoveRowUp = (index) => moveRowUp(rows, setRows, index);
  const onMoveRowDown = (index) => moveRowDown(rows, setRows, index);
  const onDuplicateRow = (index) => duplicateRow(rows, setRows, index, nestedComponents, setNestedComponents);

  // drag functions
  const handleComponentDragStart = (event, rowIndex, colIndex) => {
    event.dataTransfer.setData('sourceRow', rowIndex.toString());
    event.dataTransfer.setData('sourceCol', colIndex.toString());
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  // utils for handleComponentDrop function
  const onComponentDrop = (event, rowIndex, colIndex, containerId = null) => {
    // Check if the event is a valid drag event
    if (event && typeof event.preventDefault === 'function') {
      handleComponentDrop(componentImports, rows, setRows, event, rowIndex, colIndex, containerId);
      savePageContentToContext();
    } else {
      console.error('Invalid drop event:', event);
    }
  };

  // A new function to handle template drops
  const onTemplateDrop = (event) => {
    console.log('PageContainer: onTemplateDrop triggered');
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();

      // Extract and parse the drag data
      const data = event.dataTransfer.getData('application/json');
      console.log('PageContainer: Raw data from drag event:', data);
      if (!data) {
        console.error('PageContainer: No template data found in drop event.');
        return;
      }

      let templateData;
      try {
        templateData = JSON.parse(data);
        console.log('PageContainer: Parsed templateData:', templateData);
      } catch (error) {
        console.error('PageContainer: Error parsing template data:', error);
        return;
      }

      if (templateData.type !== 'template' || !templateData.rowData) {
        console.error('PageContainer: Dragged data is not a valid template.', templateData);
        return;
      }

      const { rowData } = templateData;
      console.log('PageContainer: Extracted rowData:', rowData);

      // Ensure rowData.columns exists and is an array
      if (!rowData.columns || !Array.isArray(rowData.columns)) {
        console.error('PageContainer: Invalid rowData structure: "columns" is missing or not an array.', rowData);
        return;
      }

      // Delegate processing to useTemplateDrop hook
      handleTemplateDrop(event);
    } else {
      console.error('PageContainer: Invalid template drop event:', event);
    }
  };

  const handleComponentClick = (component) => {
      console.log('handleComponentClick called with component:', component);
      if (!component.id) {
        console.error('handleComponentClick: Component ID is undefined:', component);
        return;
      }

      const tokens = {
        ...component.tokens?.[viewportSize],
        ...designTokens[component.id]?.[viewportSize],
        ...editedTokens[component.id]?.[viewportSize],
      };
      const componentProps = selectedComponentProps[component.id] || getComponentProps(component.component);

      console.log('PageContainer - Tokens on component click:', tokens);
      console.log('PageContainer - Props on component click:', componentProps);

      setSelectedComponentInfo({ 
        id: component.id,
        name: component.name,
        props: componentProps,
        tokens: tokens,
      });

      // Ensure the selected component props are updated in the context as well
      updateComponentProps(component.id, componentProps);
      console.log('Updated component props after click:', componentProps);
  };

  // Render components and handle on click behaviour
  const renderedComponents = (componentsArray, rowIndex, colIndex) => {
    if (!Array.isArray(componentsArray)) {
      console.error("Invalid componentsArray:", componentsArray);
      return null;
    }

    return componentsArray.map((componentInfo, index) => {
      //console.log(`Rendering component ${componentInfo.name} with ID ${componentInfo.id}`);
      if (!componentInfo || !componentInfo.component) {
        console.warn('Invalid or unloaded component:', componentInfo);
        return null;
      }

      if (!componentInfo.id) {
        console.error('Component is missing an ID:', componentInfo);
        return null; // Prevent rendering components without ID
      }

      const Component = componentInfo.component;
      const componentId = componentInfo.id;

      const tokens = {
        ...componentInfo.tokens?.[viewportSize],
        ...designTokens[componentInfo.id]?.[viewportSize],
        ...editedTokens[componentInfo.id]?.[viewportSize],
      };

      // Use getComponentProps utility to get the component's props
      const componentProps = selectedComponentProps[componentInfo.id] || componentInfo.props || getComponentProps(componentInfo.component);

      // Update selectedComponentProps if not already set
      if (!selectedComponentProps[componentInfo.id]) {
          updateComponentProps(componentInfo.id, componentProps);
      }

      return (
        <div 
          key={componentId}
          id={componentId}
          draggable="true"
          className="rendered-component" 
          onDragStart={(e) => handleComponentDragStart(e, rowIndex, colIndex)}
          data-component-name={componentInfo.name}
          data-component-source={componentInfo.source} 
          data-s3-component-name={componentInfo.s3ComponentName || ''} 
          onClick={() => handleComponentClick(componentInfo)}
        >
          <Component 
            key={componentId}
            {...componentProps}
            id={componentId}
            tokens={tokens}
            onClick={() => handleComponentClick(componentInfo)}
          />
        </div>
      );
    });
  };

  // This function handles rendering for Container and DivContainer components with nested components
  const renderedContainerComponents = (componentInfo, rowIndex, colIndex) => {
    const tokens = {
      ...componentInfo.tokens?.[viewportSize],
      ...designTokens[componentInfo.id]?.[viewportSize],
      ...editedTokens[componentInfo.id]?.[viewportSize],
    };
    
    const componentProps = selectedComponentProps[componentInfo.id] || componentInfo.props || getComponentProps(componentInfo.component);
    
    // Update selectedComponentProps if not already set
    if (!selectedComponentProps[componentInfo.id]) {
        updateComponentProps(componentInfo.id, componentProps);
    }

    const nestedComponentsForContainer = nestedComponents[componentInfo.id] || {};

    if (componentInfo.name === 'Container' || componentInfo.name === 'HeroBanner') {
      return (
        <div
          key={componentInfo.id}
          id={componentInfo.id}
          style={{ width: '100%' }}
          onDrop={(event) => handleComponentDrop(event, rowIndex, colIndex, componentInfo.id)}
          onDragOver={(event) => event.preventDefault()}
        >
          <Container
            id={componentInfo.id}
            {...componentProps}
            tokens={tokens}
            handleComponentClick={handleComponentClick}
          >
            {Object.keys(nestedComponentsForContainer).map((columnId) => {
              const componentsArray = nestedComponentsForContainer[columnId];
              return Array.isArray(componentsArray)
                ? componentsArray.map((nestedComponent, nestedIndex) => {
                  const NestedComponent = nestedComponent.component;

                  const nestedTokens = {
                    ...nestedComponent.tokens?.[viewportSize],
                    ...designTokens[nestedComponent.id]?.[viewportSize],
                    ...editedTokens[nestedComponent.id]?.[viewportSize],
                  };
                    
                  const nestedProps = selectedComponentProps[nestedComponent.id] || nestedComponent.props || getComponentProps(nestedComponent.component);

                  // Update selectedComponentProps if not already set
                  if (!selectedComponentProps[nestedComponent.id]) {
                    updateComponentProps(nestedComponent.id, nestedProps);
                  }

                  if (!nestedComponent.id) {
                    console.error('Nested component is missing an ID:', nestedComponent);
                    return null;
                  }

                  console.log(`Rendering nested component: ${nestedComponent.name} with ID: ${nestedComponent.id}`);
                  console.log(`Nested Component Props:`, nestedProps);
                  console.log(`Nested Component Tokens:`, nestedTokens);

                  return (
                    <div
                      key={nestedComponent.id}
                      id={nestedComponent.id}
                      className="nested-component"
                      data-component-name={nestedComponent.name}
                      data-component-source={nestedComponent.source}
                      data-s3-component-name={nestedComponent.s3ComponentName || ''}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleComponentClick(nestedComponent);
                      }}
                    >
                      {nestedComponent.name === 'Container' || nestedComponent.name === 'DivContainer' ? (
                        <Container
                          id={nestedComponent.id}
                          {...nestedProps}
                          tokens={nestedTokens}
                          handleComponentClick={handleComponentClick}
                        />
                      ) : (
                        <NestedComponent 
                          id={nestedComponent.id}
                          {...nestedProps} 
                          tokens={nestedTokens}
                        />
                      )}
                    </div>
                  );
                })
              : null;
            })}
          </Container>
        </div>
      );
    }

    return null;
  };

  // useEffect hook for updating renderedRows when rows are updated
  useEffect(() => {
    console.log('PageContainer: Update renderedRows useEffect triggered');
    console.log('Dependencies:', { rows, designTokens, editedTokens, nestedComponents });
    const newRenderedRows = rows.map((row, rowIndex) => ({
      ...row,
      // Apply row tokens (using "row_<rowId>" key for the current viewport) as inline style
      rowStyle: {
        ...((designTokens[`row_${row.rowId}`]?.[viewportSize]) || {}),
        ...((editedTokens[`row_${row.rowId}`]?.[viewportSize]) || {})
      },
      components: row.components.map((columnComponents, colIndex) => {
        console.log(`Rendering row ${rowIndex + 1}, column ${colIndex + 1}`);
        return Array.isArray(columnComponents)
          ? columnComponents.map((componentInfo) =>
              componentInfo.name === 'DivContainer' || componentInfo.name === 'Container'
                ? renderedContainerComponents(componentInfo, rowIndex, colIndex)
                : renderedComponents([componentInfo], rowIndex, colIndex)
            )
          : null;
      }),
      // Build columnStyles array using "col_<colId>" tokens
      columnStyles: row.columnIds
        ? row.columnIds.map((colId) => ({
            ...((designTokens[`col_${colId}`]?.[viewportSize]) || {}),
            ...((editedTokens[`col_${colId}`]?.[viewportSize]) || {})
          }))
        : [],
    }));
    setRenderedRows(newRenderedRows);
    console.log('Updated renderedRows:', newRenderedRows);
  }, [rows, designTokens, editedTokens, nestedComponents, forceRender, viewportSize]);
  
  function normalizeNestedData(objOrArray) {
    if (!objOrArray) return [];
    if (Array.isArray(objOrArray)) return objOrArray;
    // if it's an object with columnIds => [components], map it
    return Object.entries(objOrArray).map(([colId, comps]) => ({
      columnId: colId,
      components: comps,
    }));
  }

  const savePageContentToContext = () => {
    const containerElements = document.querySelectorAll('.page-container .container');
    const pageContentStructure = Array.from(containerElements).map((containerEl) => {
      const rowId = containerEl.getAttribute('data-row-id');
      const rowEl = containerEl.querySelector('.row');
      const columnsArray = rowEl ? rowEl.querySelectorAll('.column') : [];
      return {
        rowId,
        rowTokens: {
          ...designTokens[`row_${rowId}`],
          ...editedTokens[`row_${rowId}`]
        },
        columns: Array.from(columnsArray).map((column) => {
          const colSize = parseInt(column.getAttribute('data-column-size'), 10);
          const colId = column.getAttribute('data-column-id');
          const componentsArray = column.querySelectorAll('.rendered-component');
          return {
            columnSize: colSize,
            columnId: colId,
            columnTokens: {
              ...designTokens[`col_${colId}`],
              ...editedTokens[`col_${colId}`]
            },
            components: Array.from(componentsArray).map((component) => {
              const componentName = component.getAttribute('data-component-name');
              const componentSource = component.getAttribute('data-component-source');
              const s3ComponentName = component.getAttribute('data-s3-component-name');
              const componentId = component.getAttribute('id');
              const componentProps = selectedComponentProps[componentId] || {};
              return {
                id: componentId,
                name: componentName,
                source: componentSource,
                s3ComponentName: s3ComponentName,
                props: componentProps,
                tokens: editedTokens[componentId] || {},
                nestedComponents: nestedComponents[componentId] || [],
              };
            }),
          };
        }),
      };
    });
    setPageContent(pageContentStructure);
  };

  const getColumnSize = (column) => {
      const columnSize = column.getAttribute('data-column-size');
      return columnSize ? parseInt(columnSize, 10) : null;
  };

  const handleCreateTemplate = (rowData) => {
    console.log('handleCreateTemplate called with rowData:', rowData);
    openTemplateModal(rowData);
  };

  return (
    <div 
      className="page-container" 
      style={containerStyle}
      onDrop={(event) => onTemplateDrop(event)} // New template drop handler
      onDragOver={(event) => event.preventDefault()}
      aria-label="Page Container"
    >
      {rows.map((row, rowIndex) => (
        <div 
          key={row.rowId || rowIndex} 
          id={`row_${row.rowId}`}
          className="container"
          data-row-id={row.rowId}
          onMouseOver={(e) => {
            // Only set hover if relatedTarget is outside this container
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setHoveredRow(row.rowId);
            }
          }}
          onMouseOut={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setHoveredRow(null);
            }
          }}
          onClick={(e) => {
            if (e.currentTarget === e.target) handleRowClick(row.rowId);
          }}
          style={{
            ...((designTokens[`row_${row.rowId}`]?.[viewportSize]) || {}),
            ...((editedTokens[`row_${row.rowId}`]?.[viewportSize]) || {})
          }}
        >
          {/* Show row select button if this row is hovered */}
          {hoveredRow === row.rowId && (
            <button 
              className="row-select-button"
              onClick={(e) => {
                e.stopPropagation();
                handleRowClick(row.rowId);
              }}
            >
              Select Row
            </button>
          )}
          <div className="bar-container">
            <div className="column-options">
              <button onClick={() => onHandleColumnChange(rowIndex, row.columns.length + 1)}>
                +
              </button>
              {row.columns.map((colCount, colIndex) => (
                <div key={row.columnIds ? row.columnIds[colIndex] : colIndex}>
                  <select
                    value={colCount}
                    onChange={(e) =>
                      onHandleColumnDivChange(rowIndex, colIndex, parseInt(e.target.value))
                    }
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((count) => (
                      <option key={count} value={count}>
                        {count} Col{count > 1 ? 's' : ''}
                      </option>
                    ))}
                  </select>

                  <button onClick={() => onRemoveColumn(rowIndex, colIndex)}>–</button>
                </div>
              ))}
            </div>
            <div className="row-action-buttons">
              <button onClick={() => handleCreateTemplate(rows[rowIndex])}>Create Template</button>
              <button onClick={() => onMoveRowUp(rowIndex)}><ArrowUpOutlined /></button>
              <button onClick={() => onMoveRowDown(rowIndex)}><ArrowDownOutlined /></button>
              <button onClick={() => onDuplicateRow(rowIndex)}><CopyOutlined /></button>
              <button onClick={() => onRemoveRow(rowIndex)}>Remove Row</button>
            </div>
          </div>
          <div className="row">
            {row.columns.map((colCount, colIndex) => {
              // Calculate the column id: either from row.columnIds (if available) or use the index as a fallback
              const colId = row.columnIds ? row.columnIds[colIndex] : colIndex;
              return (
                <div
                  key={colId}
                  id={`col_${colId}`}
                  className="column"
                  style={{
                    flex: `1 ${(100 / row.columns.reduce((acc, curr) => acc + curr, 0)) * colCount}%`,
                    ...((designTokens[`col_${colId}`]?.[viewportSize]) || {}),
                    ...((editedTokens[`col_${colId}`]?.[viewportSize]) || {})
                  }}
                  data-column-size={colCount}
                  data-column-id={colId}
                  onMouseOver={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                      setHoveredColumn(colId);
                    }
                  }}
                  onMouseOut={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                      setHoveredColumn(null);
                    }
                  }}
                  onClick={(e) => {
                    if (e.currentTarget === e.target) {
                      e.stopPropagation();
                      handleColumnClick(colId);
                    }
                  }}
                  onDrop={(event) => handleComponentDrop(event, rowIndex, colIndex)}
                  onDragOver={allowDrop}
                >
                  {/* Show column select button if this column is hovered */}
                  {hoveredColumn === colId && (
                    <button 
                      className="column-select-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleColumnClick(colId);
                      }}
                    >
                      Select Column
                    </button>
                  )}
                  {row.components[colIndex] &&
                    row.components[colIndex].map((component, componentIndex) => (
                      <React.Fragment key={component.id}>
                        {component.name === 'DivContainer' || component.name === 'Container'
                          ? renderedContainerComponents(component, rowIndex, colIndex)
                          : renderedComponents([component], rowIndex, colIndex)}
                      </React.Fragment>
                    ))}
                </div>
              );
            })}
          </div>
        </div>
      ))}
      <div className="add-outter-row">
        <button className="add-row" onClick={onAddRow}>Add Row</button>
      </div>
    </div>
  );
};

export default PageContainer;
