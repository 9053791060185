// frontend/src/components/RightSidebar.js
import React, { useState } from 'react';
import { MenuOutlined, CloseOutlined, CopyOutlined, SendOutlined, RightOutlined } from '@ant-design/icons';
import { ReactComponent as AiIcon } from '../assets/icons/ai-icon.svg';
import { Input, Button, message, Select, Spin, Modal } from 'antd';
import { callOpenAI } from '../utils/openai';
import axios from 'axios';
import { queryCodeEmbeddings } from '../utils/codeEmbeddings';
import defaultComponentTemplate from '../utils/templates/NewComponentTemplate';

const { TextArea } = Input;

const models = [
  'gpt-4-turbo',
  'gpt-4',
  'gpt-4o',
  'gpt-3.5-turbo-16k',
  'gpt-3.5-turbo',
  'gpt-4-0613',
];

const RightSidebar = ({ onCreateBlankComponent }) => {
  const [prompt, setPrompt] = useState('');
  const [templateText, setTemplateText] = useState(''); // holds full template (hidden)
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [selectedModel, setSelectedModel] = useState(models[0]);

  // Helper: return full template text from the imported template.
  const getFullTemplate = () => {
    return defaultComponentTemplate;
  };

  // New function: show a modal for blank component creation.
  const handleCreateBlankComponentModal = () => {
    let newName = '';
    Modal.confirm({
      title: 'Create New Component',
      content: (
        <div>
          <p>Please enter the new component’s filename (without extension):</p>
          <Input
            placeholder="e.g., MyNewComponent"
            onChange={(e) => { newName = e.target.value; }}
          />
        </div>
      ),
      okText: 'Create',
      cancelText: 'Cancel',
      onOk: async () => {
        if (!newName || newName.trim() === '') {
          message.error('Filename cannot be empty.');
          return Promise.reject();
        }
        try {
          await onCreateBlankComponent(newName.trim());
          setPrompt("The component we want to create is");
        } catch (error) {
          return Promise.reject();
        }
      }
    });
  };

  const handlePremadePromptClick = () => {
    const fullTemplate = getFullTemplate();
    setTemplateText(fullTemplate);
    handleCreateBlankComponentModal();
  };


  // Helper function to format GPT response into segments.
  const formatGPTResponse = (responseText) => {
    const segments = [];
    const regex = /```([\s\S]*?)```/g;
    let lastIndex = 0;
    let match;
    while ((match = regex.exec(responseText)) !== null) {
      if (match.index > lastIndex) {
        const textPart = responseText.substring(lastIndex, match.index).trim();
        if (textPart) {
          segments.push({ type: 'text', content: textPart });
        }
      }
      segments.push({ type: 'code', content: match[1].trim() });
      lastIndex = regex.lastIndex;
    }
    if (lastIndex < responseText.length) {
      const remaining = responseText.substring(lastIndex).trim();
      if (remaining) {
        segments.push({ type: 'text', content: remaining });
      }
    }
    return segments;
  };

  const handleSendPrompt = async () => {
    if (!prompt) {
      message.warning('Please enter a prompt.');
      return;
    }
    setLoading(true);
    setResponse('');
    try {
      const finalPrompt = templateText
        ? `Create a new component matching 100% using the component template using react window:\n\n${templateText}\n\n${prompt}`
        : prompt;
      const codeResults = await queryCodeEmbeddings(finalPrompt);
      let context = '';
      codeResults.forEach((result, index) => {
        context += `Relevant Code Snippet ${index + 1}:\n`;
        context += `File: ${result.filePath}\n`;
        context += `Function: ${result.functionName}\n`;
        context += `${result.codeSnippet}\n\n`;
      });
      const systemMessage = `You are an AI assistant helping with code generation and understanding. Use the provided code snippets to assist the user.`;
      const messages = [
        { role: 'system', content: systemMessage },
        { role: 'user', content: `Question: ${finalPrompt}\n\n${context}` },
      ];
      const result = await axios.post('/api/openai', { messages, model: selectedModel });
      const aiResponse = result.data.choices[0].message.content;
      setResponse(aiResponse);
    } catch (error) {
      console.error('Error sending prompt:', error);
      message.error('Failed to get response from AI.');
    } finally {
      setLoading(false);
    }
  };

  const formattedSegments = formatGPTResponse(response);

  return (
    <div className="CodeRightSidebar">
      <div className="ai-intro">
        <AiIcon />
        <h4>How can I help?</h4>
      </div>
      {/* Premade Prompt Section */}
      <div
        className="premade-prompt"
        onClick={handlePremadePromptClick}
      >
        <div className="promptHeading">
          <h4>Create a component</h4>
          <RightOutlined />
        </div>
        <p>
          Press here then add a brief description of the component you wish to create.
        </p>
      </div>
      <div className="CodeRightSidebarContents">
        <div className="ai-response">
          <div className="ai-model-controls">
            <Select
              value={selectedModel}
              onChange={(value) => setSelectedModel(value)}
              style={{ width: '100%', marginBottom: '10px' }}
            >
              {models.map((model) => (
                <Select.Option key={model} value={model}>
                  {model}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="ai-response-contents">
            {loading ? (
              <Spin tip="Loading..." />
            ) : response ? (
              <div className="response-item">
                <h4>Response:</h4>
                {formattedSegments.map((seg, index) => {
                  if (seg.type === 'text') {
                    return <p key={index}>{seg.content}</p>;
                  } else if (seg.type === 'code') {
                    return (
                      <div key={index} style={{ position: 'relative', margin: '10px 0' }}>
                        <pre style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '4px', overflow: 'auto' }}>
                          <code>{seg.content}</code>
                        </pre>
                        <Button
                          icon={<CopyOutlined />}
                          size="small"
                          style={{ position: 'absolute', top: '5px', right: '5px' }}
                          onClick={() => navigator.clipboard.writeText(seg.content)}
                        >
                          Copy
                        </Button>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="ai-prompt">
          <TextArea
            rows={4}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
          />
          <Button 
            className="promptButton"
            type="primary" 
            icon={<SendOutlined />} 
            onClick={handleSendPrompt} 
            style={{ marginTop: '10px' }}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
