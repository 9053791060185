// frontend/src/hooks/useNestedComponentDrop.js
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { components as componentImports } from '../components/ComponentImports';
import { loadComponentFromS3, loadUserComponentFromS3, registerDynamicComponent } from '../components/DynamicComponentLoader';
import { getComponentProps } from './getComponentProps';

const useNestedComponentDrop = (containerId, token, designTokens, editedTokens) => {
  const { addNestedComponent } = useContext(NestedComponentsContext);

  const handleNestedComponentDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const data = event.dataTransfer.getData('text/plain');
    if (!data) return;

    let dragData;
    try {
      dragData = JSON.parse(data);
    } catch (error) {
      console.error('Error parsing drag data:', error);
      return;
    }

    const { componentSource, componentName, designTokens: componentDesignTokens } = dragData;
    let ComponentModule;

    if (componentSource === 's3') {
      console.log(`Loading S3 component: ${componentName}`);
      ComponentModule = await loadComponentFromS3(componentName, token);
      if (ComponentModule) {
        registerDynamicComponent(componentImports, componentName, ComponentModule, designTokens, 's3');
      } else {
        console.error(`Failed to load component "${componentName}" from S3.`);
        return;
      }
    } else if (componentSource === 's3-user') {
      console.log(`Loading user S3 component: ${componentName}`);
      ComponentModule = await loadUserComponentFromS3(componentName, token);
      if (ComponentModule) {
        registerDynamicComponent(componentImports, componentName, ComponentModule, designTokens, 's3-user');
      } else {
        console.error(`Failed to load user component "${componentName}" from S3.`);
        return;
      }
    } else if (componentSource === 'local') {
      if (typeof componentImports[componentName] === 'function') {
        console.log(`Importing local component dynamically: "${componentName}"`);
        try {
          const module = await componentImports[componentName]();
          ComponentModule = module.default || module;
        } catch (error) {
          console.error(`Failed to dynamically import local component: "${componentName}"`, error);
          return;
        }
      } else if (componentImports[componentName]?.component) {
        ComponentModule = componentImports[componentName].component;
      } else {
        console.error(`Local component not found in imports for: "${componentName}"`);
        return;
      }
    }

    if (!ComponentModule) {
      console.error(`Component module not found or failed to load for: "${componentName}"`);
      return;
    }

    const initialProps = getComponentProps(ComponentModule);
    const newComponentTokens = {
      ...designTokens[componentName],
      ...editedTokens[componentName],
      ...componentDesignTokens,
    };

    const newComponent = {
      id: uuidv4(),
      component: ComponentModule,
      name: componentName,
      source: componentSource,
      s3ComponentName: (componentSource === 's3' || componentSource === 's3-user') ? componentName : undefined,
      props: initialProps,
      tokens: newComponentTokens,
    };

    // --- NEW: Get columnId from drop target ---
    // Try to extract column id from the drop target’s dataset.
    const dropColumnId = event.currentTarget?.dataset?.columnId || "0";
    // -------------------------------------------

    addNestedComponent(containerId, dropColumnId, newComponent);
  };

  return handleNestedComponentDrop;
};

export default useNestedComponentDrop;
