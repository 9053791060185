// frontend/src/hooks/usePageContentUtils.js
import { components as componentImports } from '../components/ComponentImports';
import { loadComponentFromS3, loadUserComponentFromS3 } from '../components/DynamicComponentLoader';
import { v4 as uuidv4 } from 'uuid';

export const usePageContentUtils = (components, selectedComponentProps) => {
  const normalizeNestedData = (nestedData) => {
    if (!nestedData) {
      console.log('[usePageContentUtils] normalizeNestedData: no nested data found.');
      return [];
    }
    if (Array.isArray(nestedData)) {
      console.log('[usePageContentUtils] normalizeNestedData: data is already an array:', nestedData);
      return nestedData;
    }
    const normalized = Object.entries(nestedData).map(([columnId, comps]) => ({
      columnId,
      components: comps,
    }));
    console.log('[usePageContentUtils] normalizeNestedData: normalized object to array:', normalized);
    return normalized;
  };

  const convertComponentRecursively = (component, nestedComponentsContext, depth = 0) => {
    console.log(`[usePageContentUtils] Converting component: ${component.id} at depth ${depth} with name: ${component.name}`);
    const mergedProps = {
      ...(component.props || {}),
      ...(selectedComponentProps[component.id] || {})
    };

    const rawNested = nestedComponentsContext[component.id];
    console.log(`[usePageContentUtils] Raw nested data for ${component.id} at depth ${depth}:`, rawNested);
    const normalizedNested = normalizeNestedData(rawNested);
    console.log(`[usePageContentUtils] Normalized nested data for ${component.id} at depth ${depth}:`, normalizedNested);

    const nestedData = normalizedNested.map(({ columnId, components: columnComps }) => {
      console.log(`[usePageContentUtils] Processing column "${columnId}" at depth ${depth + 1} for component ${component.id}`);
      return {
        columnId,
        components: columnComps.map((childComp) =>
          convertComponentRecursively(childComp, nestedComponentsContext, depth + 1)
        ),
      };
    });

    return {
      id: component.id,
      name: component.name,
      source: component.source,
      s3ComponentName: component.s3ComponentName,
      props: mergedProps,
      tokens: component.tokens || {},
      nestedComponents: nestedData,
    };
  };

  // Include rowTokens and columnTokens in the conversion.
  const convertFrontendStructureToBackendSchema = (rows, nestedComponentsContext) => {
    return rows.map((row) => {
      const columnIds = row.columnIds ? row.columnIds : row.columns.map((_, index) => uuidv4());
      return {
        rowId: row.rowId,
        rowTokens: row.rowTokens || {},
        columnIds,
        columns: row.columns.map((columnSize, columnIndex) => {
          const compsInColumn = row.components[columnIndex] || [];
          const convertedComponents = compsInColumn.map((component) =>
            convertComponentRecursively(component, nestedComponentsContext, 0)
          );
          const columnTokens = row.columnTokens ? row.columnTokens[columnIndex] : {};
          return {
            columnSize,
            columnId: columnIds[columnIndex],
            columnTokens,
            components: convertedComponents,
          };
        }),
      };
    });
  };

  return { convertFrontendStructureToBackendSchema };
};
