// frontend/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from 'axios';

// Set up Axios interceptors globally
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      // Optionally handle membership expiration or global errors here
    }
    return Promise.reject(error);
  }
);

// Expose React and ReactDOM globally
window.React = React;
window.ReactDOM = ReactDOM;

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const renderApp = async () => {
  try {
    const hostname = window.location.hostname;
    // If hostname is not the main domain, assume it's a custom domain (logged in)
    if (hostname !== 'www.webbify.io') {
      const response = await axios.get('/userurl/getByHostname', { withCredentials: true });
      const { userId, userurl } = response.data;
      const { default: PublicApp } = await import('./PublicApp');
      root.render(
        <React.StrictMode>
          <PublicApp userId={userId} userurl={userurl} pageName="" />
        </React.StrictMode>
      );
    } else {
      // For public users on the main domain, parse the URL path
      const pathParts = window.location.pathname.split('/').filter(part => part !== '');
      // Expecting URL like /{userId}/{userurl}/{optionalPageName}
      const [userId, userurl, pageName] = pathParts;
      if (userId && userurl) {
        const { default: PublicApp } = await import('./PublicApp');
        root.render(
          <React.StrictMode>
            <PublicApp userId={userId} userurl={userurl} pageName={pageName || ''} />
          </React.StrictMode>
        );
      } else {
        // If URL path does not match, assume its the logged-in app
        root.render(
          <React.StrictMode>
            <App />
          </React.StrictMode>
        );
      }
    }
  } catch (error) {
    console.error('Error determining app type:', error);
    // Fallback: parse URL path
    const pathParts = window.location.pathname.split('/').filter(part => part !== '');
    const [userId, userurl, pageName] = pathParts;
    if (userId && userurl) {
      const { default: PublicApp } = await import('./PublicApp');
      root.render(
        <React.StrictMode>
          <PublicApp userId={userId} userurl={userurl} pageName={pageName || ''} />
        </React.StrictMode>
      );
    } else {
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
    }
  }
};

root.render(<div className="app-loader">Loading...</div>);
renderApp();
