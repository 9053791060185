// frontend/src/hooks/getComponentProps.js 
export const getComponentProps = (Component) => {
  if (!Component) {
    console.warn('Component is undefined in getComponentProps.');
    console.trace();
    return {};
  }
  
  //console.log('getComponentProps: Component loaded:', Component);
  return Component.defaultProps || {};
};