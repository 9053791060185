// frontend/src/components/ComponentSidebar.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { fetchUserComponents } from './UserComponentImports';
import { components } from './ComponentImports';
import { loadComponentFromS3, loadUserComponentFromS3, registerDynamicComponent } from './DynamicComponentLoader';
import ComponentWindow from './ComponentWindow';
import { CaretRightOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

const ComponentSidebar = ({ onDragStart, token, designTokens }) => {
  const BASE_URL = 'https://www.webbify.io';
  const [testComponents, setTestComponents] = useState([]);
  // NEW: State for user-specific S3 components
  const [userS3Components, setUserS3Components] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [draggedComponent, setDraggedComponent] = useState(null);
  const hiddenDragImageRef = useRef(null);
  const userId = localStorage.getItem('userId') || '';

  useEffect(() => {
    const fetchTestComps = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/component-management/list`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('Fetched test components from S3:', response.data.components);
        setTestComponents(response.data.components);
      } catch (error) {
        console.error('Error fetching test components from S3:', error);
      }
    };
    fetchTestComps();
  }, [token]);

  // NEW: Fetch user-specific S3 components
  useEffect(() => {
    const fetchUserS3Comps = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/component-management/list-user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('Fetched user S3 components:', response.data.components);
        setUserS3Components(response.data.components);
      } catch (error) {
        console.error('Error fetching user S3 components:', error);
      }
    };
    fetchUserS3Comps();
  }, [token]);

  const handleDragStart = async (event, componentName, source = 'local') => {
    console.log(`Starting drag for component: "${componentName}", Source: "${source}"`);
    setDraggedComponent(componentName);
    const componentId = uuidv4();

    // Prepare drag data
    const componentDesignTokens = designTokens[componentName] || {};
    const dragData = {
      id: componentId,
      componentSource: source,
      componentName: componentName,
      designTokens: componentDesignTokens,
    };

    // Set drag data using 'text/plain'
    event.dataTransfer.setData('text/plain', JSON.stringify(dragData));
    event.dataTransfer.setData('componentSource', source);
    event.dataTransfer.setData('componentName', componentName);
    event.dataTransfer.setData('designTokens', JSON.stringify(componentDesignTokens));
    console.log(`Set drag data for component "${componentName}":`, {
      componentSource: source,
      designTokens: componentDesignTokens,
    });
  };

  const handleDragEnd = () => {
    console.log(`Ending drag for component: "${draggedComponent}"`);
    setDraggedComponent(null);
  };

  const handleOpenWindow = (componentName) => {
    console.log(`Opening component window for "${componentName}"`);
    setSelectedComponent({ componentName, designTokens: designTokens[componentName] });
  };

  return (
    <div className="left-sidebar" aria-label="Component Sidebar">
      <h4>App Components</h4>
      <ul aria-label="List of App components">
        {Object.keys(components).map((componentName) => (
          <li
            key={componentName}
            draggable
            onDragStart={(e) => handleDragStart(e, componentName, 'local')}
            onDragEnd={handleDragEnd}
            className={draggedComponent === componentName ? 'dragging' : ''}
          >
            <div className="component-left-content">
              <p>{componentName}</p>
            </div>
            <button onClick={() => handleOpenWindow(componentName)}><CaretRightOutlined /></button>
          </li>
        ))}
      </ul>
      {/* NEW: List for user-specific S3 components */}
      <h4>My Components</h4>
      <ul aria-label="List of user S3 components">
        {userS3Components.map((component) => {
          // Show only the filename (remove userId/ prefix if present)
          const displayName = component.key.startsWith(`${userId}/`)
            ? component.key.slice(userId.length + 1)
            : component.key;
          return (
            <li
              key={component.key}
              draggable
              onDragStart={(e) => handleDragStart(e, component.key, 's3-user')}
              onDragEnd={handleDragEnd}
              className={draggedComponent === component.key ? 'dragging' : ''}
            >
              <div className="component-left-content">
                <p>{displayName}</p>
              </div>
              <button>
                <CaretRightOutlined />
              </button>
            </li>
          );
        })}
      </ul>
      <h4>Shared Components</h4>
      <ul aria-label="List of Global s3 Components">
        {testComponents
          .filter((component) => !component.key.includes('/')) // Only show base-level files
          .map((component) => (
            <li
              key={component.key}
              draggable
              onDragStart={(e) => handleDragStart(e, component.key, 's3')}
              onDragEnd={handleDragEnd}
              className={draggedComponent === component.key ? 'dragging' : ''}
            >
              <div className="component-left-content">
                <p>{component.key}</p>
              </div>
              <button>
                <CaretRightOutlined />
              </button>
            </li>
          ))}
      </ul>
      {selectedComponent && (
        <ComponentWindow
          componentName={selectedComponent.componentName}
          designTokens={selectedComponent.designTokens}
        />
      )}
      <div ref={hiddenDragImageRef} style={{ position: 'absolute', top: '-1000px', left: '-1000px', visibility: 'hidden' }} />
    </div>
  );
};

export default ComponentSidebar;
