// frontend/src/pages/MediaPage.js
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import MediaSidebar from '../components/media/MediaSidebar';
import MediaContainer from '../components/media/MediaContainer';
import MediaActions from '../components/media/MediaActions'; 
import axios from 'axios';
import { Button, Modal, Select, Upload, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../MediaPage.css';
import FeedbackPopup from '../components/FeedbackPopup';

const { Dragger } = Upload;
const { Option } = Select;

const MediaPage = ({ isLoggedIn, setIsLoggedIn }) => {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [refresh, setRefresh] = useState(false); // To trigger re-fetching
  const [allFiles, setAllFiles] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for initial load
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [targetSubfolder, setTargetSubfolder] = useState(null);
  const [fileList, setFileList] = useState([]);

  // Fetch all files from all subfolders and sort by date
  const fetchAllFiles = async () => {
    setLoading(true); // Start loading
    try {
      const token = localStorage.getItem('token');
      const fileTypes = ['Images', 'Icons', 'Video', 'Documents', 'Music'];
      let allFilesArray = [];

      for (const fileType of fileTypes) {
        const response = await axios.get('/media/list', {
          headers: { Authorization: `Bearer ${token}` },
          params: { fileType },
        });
        allFilesArray = allFilesArray.concat(response.data.files);
      }

      // Sort by 'lastModified' date, most recent first
      allFilesArray.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
      setAllFiles(allFilesArray);
    } catch (error) {
      console.error('Error fetching all files:', error);
      message.error('Failed to fetch all files.');
    } finally {
      setLoading(false); // End loading
    }
  };

  // Trigger fetching all files on initial load
  useEffect(() => {
    if (!selectedFolder) {
      fetchAllFiles();
    }
  }, [selectedFolder, refresh]);

  const handleFolderSelect = (folderPath) => {
    setSelectedFolder(folderPath);
  };

  const triggerRefresh = () => {
    setRefresh(!refresh);
  };

  const openUploadModal = () => {
    setTargetSubfolder(selectedFolder ? selectedFolder.split('/')[1] : null); // Pre-select folder if available
    setIsUploadModalVisible(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalVisible(false);
    setFileList([]);
    setTargetSubfolder(null);
  };

  const handleFileChange = (info) => setFileList(info.fileList);

  const handleUpload = async () => {
    if (!targetSubfolder || fileList.length === 0) {
      message.error('Please select a subfolder and add files to upload.');
      return;
    }
    
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('fileType', targetSubfolder);

    fileList.forEach((file) => {
      formData.append('file', file.originFileObj);
    });

    try {
      await axios.post('/media/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('File uploaded successfully.');
      triggerRefresh();
      closeUploadModal();
    } catch (error) {
      message.error('File upload failed.');
    }
  };

  return (
    <div className="mediaWrapper">
      <Header className="mini-main-header" isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div className="MediaContents">
        <MediaSidebar onFolderSelect={handleFolderSelect} />
        <div className="MediaMain">
          {loading ? ( // Show spinner if loading
            <div className="loading-spinner">
              <Spin size="large" tip="Loading file..." />
            </div>
          ) : selectedFolder ? (
            <>
              <div className="MediaMainTopBar">
                <h3>{selectedFolder} Files</h3>
                <Button className="mediaUploadButton" icon={<UploadOutlined />} onClick={openUploadModal}>Upload Files</Button>
                <MediaActions
                  selectedFolder={selectedFolder}
                  onActionCompleted={triggerRefresh}
                  openUploadModal={openUploadModal}
                />
              </div>
              <MediaContainer selectedFolder={selectedFolder} refresh={refresh} />
            </>
          ) : (
            <div className="all-files-container">
              <div className="MediaMainTopBar">
                <h3>All Recent Files</h3>
                <Button className="mediaUploadButton" icon={<UploadOutlined />} onClick={openUploadModal}>
                  Upload Files
                </Button>
              </div>
              <MediaContainer selectedFolder={null} mediaItems={allFiles} />
            </div>
          )}
        </div>
        <FeedbackPopup page="Media" />
      </div>

      {/* Upload Modal */}
      <Modal
        title="File Upload"
        visible={isUploadModalVisible}
        onCancel={closeUploadModal}
        onOk={handleUpload}
        okText="Upload"
        okButtonProps={{ disabled: !targetSubfolder || fileList.length === 0 }}
      >
        <Select
          placeholder="Select a subfolder"
          value={targetSubfolder}
          onChange={setTargetSubfolder}
          style={{ width: '100%', marginBottom: '16px' }}
        >
          {['Images', 'Icons', 'Video', 'Documents', 'Music'].map((subFolder) => (
            <Option key={subFolder} value={subFolder}>
              {subFolder}
            </Option>
          ))}
        </Select>

        <Dragger
          multiple
          fileList={fileList}
          onChange={handleFileChange}
          beforeUpload={() => false} // Prevents automatic upload
          style={{ padding: '16px', border: '1px dashed #d9d9d9', backgroundColor: '#fafafa' }}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">Click or drag files here to upload</p>
        </Dragger>
      </Modal>

    </div>
  );
};

export default MediaPage;
