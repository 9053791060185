// frontend/src/pages/CodeEditor.js
import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BuilderContext } from '../contexts/BuilderContext';
import { Layout, Button, message } from 'antd';
import { CopyOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { ReactComponent as DragIcon } from '../assets/icons/drag-icon.svg';
import { ReactComponent as JiraIcon } from '../assets/icons/jira-icon.svg';

import '../codeEditor.css';
import Header from '../components/Header';
import '../meAInterface.css';
import CodeEditorFilesSidebar from '../components/codeEditor/CodeEditorFilesSidebar';
import RightSidebar from '../components/RightSidebar';
import { fetchPageContent } from '../utils/fetchPageContentUtils';
import { usePageContentUtils } from '../hooks/usePageContentUtils';
import MonacoEditor from '../components/MonacoEditor';
import MenuBar from '../components/codeEditor/MenuBar';
import CodeEditorComponentSidebar from '../components/codeEditor/CodeEditorComponentSidebar';
import NewComponentTemplate from '../utils/templates/NewComponentTemplate';
import FeedbackPopup from '../components/FeedbackPopup';

const { Content } = Layout;

const CodeEditor = ({ isLoggedIn, setIsLoggedIn }) => {
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(false);
  const [leftCode, setLeftCode] = useState({ code: '', language: 'json' });
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const userId = localStorage.getItem('userId') || '';
  const [activePage, setActivePage] = useState(null);
  const [activePageId, setActivePageId] = useState(null);
  const [pages, setPages] = useState([]);
  const [pageContents, setPageContents] = useState({});
  const [switchLeftSidebar, setSwitchLeftSidebar] = useState(true);

  const [components, setComponents] = useState([]);
  const [designTokens, setDesignTokens] = useState({});
  const [editedTokens, setEditedTokens] = useState({});
  const [nestedComponents, setNestedComponents] = useState({});
  const [selectedComponentInfo, setSelectedComponentInfo] = useState({});
  const [componentCode, setComponentCode] = useState('');

  const [initialHeight, setInitialHeight] = useState(0);
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);

  const { convertFrontendStructureToBackendSchema } = usePageContentUtils();

  useEffect(() => {
    if (editorContainerRef.current) {
      const calculatedHeight = editorContainerRef.current.clientHeight;
      setInitialHeight(calculatedHeight);
      document.documentElement.style.setProperty('--initial-editor-height', `${calculatedHeight}px`);
    }
  }, []);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const pagesData = await fetchPageContent(token, {}, {}, {}, () => {}, {}, () => {}, {});
        if (pagesData) {
          setPages(pagesData.map(page => ({ pageName: page.pageName, pageId: page.pageId })));
          const pageContentsMap = pagesData.reduce((acc, page) => {
            acc[page.pageName] = page.content;
            return acc;
          }, {});
          setPageContents(pageContentsMap);
        }
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    };

    fetchPages();
  }, [token]);

  useEffect(() => {
    async function fetchComponents() {
      try {
        const response = await axios.get('https://www.webbify.io/components', {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data) {
          setComponents(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch components:', error);
        message.error('Failed to fetch components');
      }
    }

    fetchComponents();
  }, [token]);

  const handleRightSidebarToggle = () => {
    setIsRightSidebarCollapsed(prevValue => !prevValue);
  };

  const handleLeftCodeChange = value => {
    setLeftCode({ ...leftCode, code: value });
  };

  const handleCopy = () => {
    editorRef.current.handleCopy();
  };

  const handleWrapUnwrap = () => {
    message.info('Word wrap toggling is not implemented yet.');
  };

  const handleSelectPage = pageName => {
    const selectedPage = pages.find(page => page.pageName === pageName);
    setActivePage(pageName);
    setActivePageId(selectedPage.pageId);
    const pageContent = pageContents[pageName];
    setLeftCode({
      code: JSON.stringify(pageContent, null, 2),
      language: 'json'
    });
  };

  // Save Pages-Files to MongoDB (unchanged)
  const handleSave = async () => {
    if (!activePage || !activePageId) {
      message.error('No active page to save');
      return;
    }

    try {
      const updatedContent = JSON.parse(leftCode.code);
      const backendSchema = convertFrontendStructureToBackendSchema(
        updatedContent.props.rows || updatedContent,
        nestedComponents
      );

      await axios.put(
        'https://www.webbify.io/pageContent',
        {
          userId: userId,
          pageId: activePageId,
          pageName: activePage,
          content: { rows: backendSchema },
          designTokens,
          editedTokens,
          nestedComponents
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success('Page content saved successfully');
    } catch (error) {
      message.error('Failed to save page content. Invalid JSON.');
      console.error('Failed to parse JSON or save content:', error);
    }
  };

  const cleanupComponentCode = async (componentId) => {
    try {
      await axios.delete(`https://www.webbify.io/components/${componentId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('MongoDB duplicate component code deleted successfully.');
    } catch (error) {
      console.error('Error deleting MongoDB duplicate component code:', error);
    }
  };

  // Save Component to S3 folder appcomponents bucket 
  const saveComponentCodeToS3 = async () => {
    if (!leftCode.code || !selectedComponentInfo.name) {
      message.error('No component code or name specified');
      return;
    }

    try {
      // Create a file from the component code; we use .js since our S3 endpoint uploads .js files
      const fileName = `${selectedComponentInfo.name}.js`;
      const file = new File([leftCode.code], fileName, { type: 'application/javascript' });

      // Prepare FormData for the file upload
      const formData = new FormData();
      formData.append('component', file);
      // Optionally include the MongoDB component ID so you know which one to delete later
      formData.append('componentId', selectedComponentInfo.id);

      // Upload to S3 via your component management endpoint
      const response = await axios.post(
        'https://www.webbify.io/component-management/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Component uploaded to S3 successfully!');
      // Mark the component as an S3 component in state
      setSelectedComponentInfo(prev => ({
        ...prev,
        source: 's3',
        s3ComponentName: fileName,
        code: leftCode.code,
      }));

      // Now trigger the MongoDB cleanup in the background
      cleanupComponentCode(selectedComponentInfo.id);
    } catch (error) {
      console.error('Failed to save component to S3:', error);
      message.error(`Failed to save component: ${error.response?.data?.message || error.message}`);
    }
  };

  // user folder based save
  const saveUserComponentCodeToS3 = async () => {
    if (!leftCode.code || !selectedComponentInfo.name) {
      message.error('No component code or name specified');
      return;
    }
    try {
      const fileName = `${selectedComponentInfo.name}.js`;
      const file = new File([leftCode.code], fileName, { type: 'application/javascript' });
      const formData = new FormData();
      formData.append('component', file);
      formData.append('componentId', selectedComponentInfo.id);

      const response = await axios.post(
        'https://www.webbify.io/component-management/upload-user',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Component uploaded to user folder successfully!');
      setSelectedComponentInfo(prev => ({
        ...prev,
        source: 's3-user',
        s3ComponentName: fileName,
        code: leftCode.code,
      }));

      // Optionally, clean up the MongoDB duplicate if needed
      cleanupComponentCode(selectedComponentInfo.id);
    } catch (error) {
      console.error('Failed to save component to user folder:', error);
      message.error(`Failed to save component: ${error.response?.data?.message || error.message}`);
    }
  };

  /* // Original MongoDB saving function (backup)
  const saveComponentCodeToMongo = async () => {
    if (!leftCode.code || !selectedComponentInfo.name) {
      message.error('No component code or name specified');
      return;
    }

    const apiUrl = 'https://www.webbify.io/components';
    const headers = { Authorization: `Bearer ${token}` };
    const componentData = {
      name: selectedComponentInfo.name,
      code: leftCode.code,
      userId: userId
    };

    try {
      let response;
      if (selectedComponentInfo.id) {
        response = await axios.put(`${apiUrl}/${selectedComponentInfo.id}`, componentData, { headers });
        const updatedComponents = components.map(comp =>
          comp._id === selectedComponentInfo.id ? { ...comp, code: leftCode.code } : comp
        );
        setComponents(updatedComponents);
      } else {
        response = await axios.post(apiUrl, componentData, { headers });
        setComponents([...components, { ...componentData, _id: response.data._id }]);
      }
      message.success('Component saved to MongoDB successfully!');
      setSelectedComponentInfo(prev => ({ ...prev, code: leftCode.code }));
    } catch (error) {
      console.error('Failed to save component:', error);
      message.error(`Failed to save component: ${error.response.data.message}`);
    }
  };
  */

  // Use the S3 saving function:
  const saveComponentCode = saveComponentCodeToS3;

  const handleSwitchSidebar = (sidebar) => {
    setSwitchLeftSidebar(sidebar === 'pages');
  };

  const handleComponentSelect = (item) => {
    // item will either have a name (for MongoDB based or default components)
    // or a key if it came from S3 user folder
    if (item.key) {
      // For S3 user components, the sidebar already fetches and passes content
      setSelectedComponentInfo({ name: item.key, code: item.code || '', id: item.key });
      setLeftCode({ code: item.code || '', language: 'javascript' });
    } else {
      setSelectedComponentInfo({ name: item.name, code: item.code, id: item._id });
      setLeftCode({ code: item.code, language: 'javascript' });
    }
  };

  // Add this function in CodeEditor.js (e.g., after your other functions)
  const handleCreateBlankComponent = async (componentName) => {
    // Remove any extension if provided, then append ".js" later.
    const strippedName = componentName.replace(/\.[^/.]+$/, '');
    // Create a blank file template that includes a comment with the file name.
    const blankTemplate = `// ${strippedName}.js\n\n`;

    const apiUrl = 'https://www.webbify.io/components';
    const componentData = {
      name: strippedName,
      code: blankTemplate, // Use the blank template with the filename comment.
      userId: userId
    };

    try {
      const response = await axios.post(apiUrl, componentData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      // Update state to include the new blank component
      setComponents((prevComponents) => [
        ...prevComponents,
        { ...response.data, code: blankTemplate }
      ]);
      setSelectedComponentInfo({ name: strippedName, code: blankTemplate, id: response.data._id });
      setLeftCode({ code: blankTemplate, language: 'javascript' });
      message.success('Blank component created successfully!');
    } catch (error) {
      console.error("Failed to create blank component:", error);
      message.error('Failed to create blank component.');
      throw error;
    }
  };


  const handleCreateComponent = async (componentName) => {
    const apiUrl = 'https://www.webbify.io/components';
    const componentData = {
      name: componentName,
      code: NewComponentTemplate,
      userId: userId
    };

    try {
      const response = await axios.post(apiUrl, componentData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const newComponent = { ...response.data, code: NewComponentTemplate };
      setComponents(prevComponents => [...prevComponents, newComponent]);
      setSelectedComponentInfo({ name: componentName, code: NewComponentTemplate, id: newComponent._id });
      setLeftCode({ code: NewComponentTemplate, language: 'javascript' });
      message.success('Component created successfully!');
    } catch (error) {
      console.error('Failed to create component:', error);
      message.error('Failed to create component: ' + error.response?.data.message);
    }
  };

  return (
    <BuilderContext.Provider value={true}>
      <div className="codeEditor">
        <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} className="mini-main-header" />
        <div className="codeEditor-container">
          <div className="cEditLeftSidebar">
            <div className="LeftSideBarTopControls">
              <div className="DragHandler">
                <DragIcon />
              </div>
              <button onClick={() => handleSwitchSidebar('pages')}
                className={!switchLeftSidebar ? 'active' : ''}
              >
                Pages
              </button>
              <button onClick={() => handleSwitchSidebar('components')}
                className={!switchLeftSidebar ? 'active' : ''}
              >
                Components
              </button>
            </div>
            {switchLeftSidebar ? (
              <CodeEditorFilesSidebar
                pages={pages.map(page => page.pageName)}
                activePage={activePage}
                onSelectPage={handleSelectPage}
              />
            ) : (
              <CodeEditorComponentSidebar
                onSelectComponent={handleComponentSelect}
                savedComponents={components}
                onCreateComponent={handleCreateComponent}
                token={token}
              />
            )}
          </div>

          {/* Code Container */}
          <div className="CodeEditorMain" ref={editorContainerRef}>
            <div className="code-editor-top-bar">
              <MenuBar
                onSave={handleSave}
                onSaveComponent={saveComponentCode}
                onSaveUserComponent={saveUserComponentCodeToS3}
                onCopy={handleCopy}
                onUndo={() => editorRef.current.handleUndo()}
                onRedo={() => editorRef.current.handleRedo()}
                onSearch={() => editorRef.current.handleSearch()}
                onWrapUnwrap={handleWrapUnwrap}
                isWrapped={leftCode.lineWrapping}
                onSplitView={() => editorRef.current.toggleSplitView()}
              />
            </div>
            <div className="CodeMirrorContainer">
              <MonacoEditor
                ref={editorRef}
                value={leftCode.code}
                onChange={handleLeftCodeChange}
                language={leftCode.language}
              />
            </div>
          </div>
          <div className="cEditRightSidebar">
            <div className="RightSideBarTopControls">
              <div className="DragHandler">
                <DragIcon />
              </div>
              <button>Ai Code</button>
              <button className="temp-disabled">Ai Image</button>
              <button className="temp-disabled">
                <JiraIcon />Jira(5)
              </button>
            </div>
            <RightSidebar onCreateBlankComponent={handleCreateBlankComponent} />
          </div>
          <FeedbackPopup page="CodeEditor" />
        </div>
      </div>
    </BuilderContext.Provider>
  );
};

export default CodeEditor;