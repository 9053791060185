// frontend/src/components/codeEditor/CodeEditorComponentSidebar.js
import React, { useState, useEffect, useContext } from 'react';
import { List, Collapse, Input, Button, message } from 'antd';
import { EditOutlined, DeleteOutlined, CaretRightOutlined } from '@ant-design/icons';
import axios from 'axios';
import { componentCodeImports } from '../ComponentCodeImports';
import { BuilderContext } from '../../contexts/BuilderContext';

const { Panel } = Collapse;

const CodeEditorComponentSidebar = ({ onSelectComponent, savedComponents, onCreateComponent, token }) => {
  const builderContext = useContext(BuilderContext);
  const [componentsList, setComponentsList] = useState([]);
  const [userComponents, setUserComponents] = useState([]);
  const [userS3Components, setUserS3Components] = useState([]);
  const [newComponentName, setNewComponentName] = useState('');
  const [activeComponent, setActiveComponent] = useState(null);

  // Load component codes (default and MongoDB-based user components)
  const loadComponentCodes = async () => {
    try {
      const defaultComponents = await Promise.all(
        Object.entries(componentCodeImports).map(async ([name, loader]) => {
          const code = await loader();
          return { name, code, type: 'default' };
        })
      );

      const userSpecificComponents = savedComponents.map(comp => ({
        ...comp,
        type: 's3'
      }));

      setComponentsList(defaultComponents);
      setUserComponents(userSpecificComponents);
    } catch (error) {
      console.error('Failed to load component codes:', error);
      message.error('Failed to load component codes.');
    }
  };

  // Load user-specific S3 components
  const loadUserS3Components = async () => {
    try {
      const response = await axios.get('https://www.webbify.io/component-management/list-user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && response.data.components) {
        setUserS3Components(response.data.components);
      }
    } catch (error) {
      console.error('Failed to fetch user S3 components:', error);
      message.error('Failed to fetch user S3 components.');
    }
  };

  useEffect(() => {
    if (builderContext) {
      loadComponentCodes();
      loadUserS3Components();
    }
  }, [builderContext, savedComponents, token]);

  const handleCreateComponent = async () => {
    if (newComponentName.trim()) {
      await onCreateComponent(newComponentName.trim());
      setNewComponentName('');
    } else {
      message.error('Component name cannot be empty');
    }
  };

  // For S3 components, we want to display only the filename (i.e. remove the userId folder prefix)
  const getDisplayFileName = (key) => {
    // Assume the key format is "userId/filename"
    const parts = key.split('/');
    return parts.length > 1 ? parts.slice(1).join('/') : key;
  };

  // New: Handle renaming a user S3 component
  const handleRenameS3Component = async (item) => {
    const originalFileName = getDisplayFileName(item.key);
    const newName = prompt("Enter the new file name (with .js extension):", originalFileName);
    if (!newName || newName.trim() === '') {
      message.error("Filename cannot be empty.");
      return;
    }
    try {
      const response = await axios.put(
        'https://www.webbify.io/component-management/rename-user',
        { oldName: originalFileName, newName: newName },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      message.success(response.data.message || "Component renamed successfully.");
      // Reload user S3 components after renaming
      loadUserS3Components();
    } catch (error) {
      console.error("Error renaming component:", error);
      message.error("Failed to rename component.");
    }
  };

  // New: Handle deleting a user S3 component
  const handleDeleteS3Component = async (item) => {
    const fileName = getDisplayFileName(item.key);
    if (!window.confirm(`Are you sure you want to delete ${fileName}?`)) return;
    try {
      const response = await axios.delete(
        'https://www.webbify.io/component-management/delete-user',
        {
          data: { componentName: fileName },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      message.success(response.data.message || "Component deleted successfully.");
      // Reload user S3 components after deletion
      loadUserS3Components();
    } catch (error) {
      console.error("Error deleting component:", error);
      message.error("Failed to delete component.");
    }
  };

  // Modified handleComponentSelect to support S3 items.
  const handleComponentSelect = async (item) => {
    if (item.key) {
      // This item came from S3. The key is in the format "userId/filename".
      let fileName = getDisplayFileName(item.key);
      try {
        const response = await axios.get(`https://www.webbify.io/component-management/fetch-user/${fileName}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const code = response.data;
        // Pass an object with a _id (using the filename) and type "s3-user"
        onSelectComponent({ name: fileName, code, _id: fileName, type: 's3-user' });
      } catch (error) {
        message.error('Failed to fetch component from S3');
        console.error(error);
      }
    } else {
      onSelectComponent(item);
    }
    setActiveComponent(item.name || item.key);
  };

  return (
    <div className="codeComponentSidebar">
      <div className="files-sidebar-controls">
        <Input
          placeholder="Component name"
          value={newComponentName}
          onChange={e => setNewComponentName(e.target.value)}
        />
        <Button onClick={handleCreateComponent}>Create</Button>
      </div>

      <Collapse className="sidebar-collapse-wrapper" defaultActiveKey={['1', '2', '3']} accordion>
        <Panel header="Default Components" key="1">
          <List
            itemLayout="horizontal"
            dataSource={componentsList}
            renderItem={item => (
              <List.Item
                className={activeComponent === item.name ? 'active' : ''}
                onClick={() => handleComponentSelect(item)}
              >
                <List.Item.Meta title={item.name} />
              </List.Item>
            )}
          />
        </Panel>

        <Panel header="Testing features" key="2">
          <List
            itemLayout="horizontal"
            dataSource={userComponents}
            renderItem={item => (
              <List.Item
                className={activeComponent === item.name ? 'active' : ''}
                onClick={() => handleComponentSelect(item)}
              >
                <List.Item.Meta title={item.name} />
              </List.Item>
            )}
          />
        </Panel>

        <Panel header="My S3 Components" key="3" className="codeUserComponents">
          <List
            itemLayout="horizontal"
            dataSource={userS3Components}
            renderItem={item => {
              const displayName = getDisplayFileName(item.key);
              return (
                <List.Item
                  className={activeComponent === item.key ? 'active' : ''}
                  actions={[
                    <EditOutlined key="rename" onClick={() => handleRenameS3Component(item)} />,
                    <DeleteOutlined key="delete" onClick={() => handleDeleteS3Component(item)} />
                  ]}
                  onClick={() => handleComponentSelect(item)}
                >
                  <List.Item.Meta title={displayName} />
                </List.Item>
              );
            }}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default CodeEditorComponentSidebar;
