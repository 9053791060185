// frontend/src/components/PropertiesSidebar.js
import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { TokensContext } from '../contexts/TokensContext';
import { ViewportContext } from '../contexts/ViewportContext';
import { SelectedComponentContext } from '../contexts/SelectedComponentContext';

import { isColor } from './colorUtils';
import { Dropdown, Menu, Collapse, Input, Row, Col, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { SideTop, SideRight, SideBottom, SideLeft, TypeLineHeight, TypeFontSize, TypeFontFamily, TypeFontWeight, SizeHeight, SizeWidth, PaddingTop, PaddingRight, PaddingBottom, PaddingLeft } from '../assets/icons/properties-sidebar/icons'; 
import { JustifyContentFlexStart, JustifyContentFlexEnd, AlignItemsFlexStart, AlignItemsFlexEnd, JustifyContentCenter, AlignItemsCenter, MoreFlexOptions, AlignItemsSpaceBetween, JustifyContentSpaceBetween, AlignItemsSpaceAround, JustifyContentSpaceAround, AlignItemsSpaceEvenly, JustifyContentSpaceEvenly, FlexWrapNowrap, FlexWrapWrap, FlexDirectionRowReverse, FlexDirectionRow, FlexDirectionColumn, FlexDirectionColumnReverse } from '../assets/icons/properties-sidebar/icons'; 

import { ReactComponent as ArrowHeadUp } from '../assets/icons/properties-sidebar/arrow-head-up.svg';
import { ReactComponent as ArrowHeadDown } from '../assets/icons/properties-sidebar/arrow-head-down.svg';

import CustomExpandIcon from './properties-sidebar/CustomExpandIcon';
import FlexOptions from './properties-sidebar/FlexOptions';
import DimensionsSpacingOptions from './properties-sidebar/DimensionsAndSpacing';
import ColorOptions from './properties-sidebar/ColorOptions';
import { renderColorPicker } from './properties-sidebar/ColorOptionsHelper';
import DisplayOptions from './properties-sidebar/DisplayOptions';
import BorderOptions from './properties-sidebar/BorderOptions';
import PositionOptions from './properties-sidebar/PositionOptions';
import CustomCSSPanel from './properties-sidebar/CustomCSSPanel';
import FloatOptions from './properties-sidebar/FloatOptions';
import { getRelevantTokens } from './properties-sidebar/utils';
import StaticContentPanel from './properties-sidebar/StaticContentPanel';
import '../propertiesSidebar.css';
import { debounce } from 'lodash';

const PropertiesSidebar = ({
    fetchTokens,
    token,
}) => {
    const { editedTokens, setEditedTokens, updateDesignTokens, globalTokens, designTokens } = useContext(TokensContext);
    const viewportSize = useContext(ViewportContext);
    const { selectedComponentInfo } = useContext(SelectedComponentContext);

    const [localValues, setLocalValues] = useState({});
    const [colorValue, setColorValue] = useState(null);
    const [tokenNames, setTokenNames] = useState({});
    const { Panel } = Collapse;

    // Helper to derive the token key. For rows/columns, prepend "row_" or "col_"
    const getTokenKey = () => {
      if (!selectedComponentInfo || !selectedComponentInfo.id) return null;
      if (selectedComponentInfo.type === 'row') return `row_${selectedComponentInfo.id}`;
      if (selectedComponentInfo.type === 'column') return `col_${selectedComponentInfo.id}`;
      return selectedComponentInfo.id;
    };

    useEffect(() => {
      console.log('Updated tokens:', { globalTokens, designTokens, editedTokens });
    }, [globalTokens, designTokens, editedTokens]);

    useEffect(() => {
      if (selectedComponentInfo) {
        // Validate color tokens
        const colorToken = selectedComponentInfo.tokens?.[viewportSize]?.color;
        if (colorToken && !/^#([0-9A-F]{3}){1,2}$/i.test(colorToken)) {
          console.warn(`Invalid color token format: "${colorToken}". Expected format is "#rrggbb".`);
          // Optionally, set a default color or notify the user
          updateDesignTokens(selectedComponentInfo.id, { color: '#000000' }, viewportSize);
        }
      }
    }, [selectedComponentInfo, updateDesignTokens, viewportSize]);

    // Initialize localValues and tokenNames based on the selected component’s tokens.
    useEffect(() => {
      if (selectedComponentInfo && selectedComponentInfo.id) {
        const tokenKey = getTokenKey();
        let componentTokens = {};
        if (selectedComponentInfo.type === 'row' || selectedComponentInfo.type === 'column') {
          componentTokens =
            editedTokens[tokenKey]?.[viewportSize] ||
            designTokens[tokenKey]?.[viewportSize] ||
            {};
        } else {
          componentTokens =
            editedTokens[selectedComponentInfo.id]?.[viewportSize] ||
            designTokens[selectedComponentInfo.id]?.[viewportSize] ||
            (selectedComponentInfo.tokens && selectedComponentInfo.tokens[viewportSize]) ||
            {};
        }

        console.log('PropertiesSidebar - componentTokens for selected item:', componentTokens);

        const newTokenNames = {};
        const newLocalValues = {};
        Object.keys(componentTokens).forEach((propertyType) => {
          const value = componentTokens[propertyType];
          const globalToken = Object.entries(globalTokens).find(([, tokenValue]) => tokenValue === value);
          if (globalToken) {
            newTokenNames[propertyType] = globalToken[0];
          }
          newLocalValues[propertyType] = value;
        });
        setTokenNames(newTokenNames);
        setLocalValues(newLocalValues);

        console.log('PropertiesSidebar: tokenNames set to:', newTokenNames);
        console.log('PropertiesSidebar: localValues initialized to:', newLocalValues);
      }
    }, [selectedComponentInfo, globalTokens, editedTokens, designTokens, viewportSize]);

    const handleTokenChange = useCallback(
      debounce((propertyType, newValue) => {
        if (!selectedComponentInfo || !selectedComponentInfo.id) {
          console.error('handleTokenChange: selectedComponentInfo is undefined.');
          return;
        }
        const tokenKey = getTokenKey();
        const newTokens = {
          ...editedTokens,
          [tokenKey]: {
            ...editedTokens[tokenKey],
            [viewportSize]: {
              ...(editedTokens[tokenKey]?.[viewportSize] || {}),
              [propertyType]: newValue,
            },
          },
        };
        console.log('PropertiesSidebar: handleTokenChange called, newTokens:', newTokens);
        setEditedTokens(newTokens);
        updateDesignTokens(tokenKey, { [propertyType]: newValue }, viewportSize);
        setTokenNames((prevTokenNames) => ({
          ...prevTokenNames,
          [tokenKey]: {
            ...prevTokenNames[tokenKey],
            [propertyType]: '', // Clear token name on manual input
          },
        }));
        console.log('PropertiesSidebar: handleTokenChange updated tokens:', newTokens);
      }, 300),
      [editedTokens, viewportSize, selectedComponentInfo, updateDesignTokens]
    );

    useEffect(() => {
      console.log('PropertiesSidebar: editedTokens updated:', editedTokens);
    }, [editedTokens]);

    const getTypographyOptions = (propertyType) => {
      if (!globalTokens || Object.keys(globalTokens).length === 0) {
        return [];
      }

      const typographyTokens = Object.entries(globalTokens).filter(([key]) =>
        key.startsWith(`typography.${propertyType}`)
      );

      return typographyTokens.map(([key, value]) => ({
        name: key,
        value: value,
      }));
    };

    const renderTypographyDropdown = (propertyType) => {
      if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

      const componentId = selectedComponentInfo.id;
      const tokenName = tokenNames[componentId]?.[propertyType] || '';
      const inputValue =
        editedTokens[componentId]?.[viewportSize]?.[propertyType] ||
        designTokens[componentId]?.[viewportSize]?.[propertyType] ||
        selectedComponentInfo.tokens?.[propertyType] ||
        localValues[propertyType] || // Check local values as a fallback
        '';

      const options = getTypographyOptions(propertyType);

      console.log(`Rendering Typography Dropdown for ${propertyType}: inputValue="${inputValue}", tokenName="${tokenName}"`);

      // Define handleBlurOrEnter
      const handleBlurOrEnter = (e) => {
        if (e.type === 'blur' || e.key === 'Enter') {
          console.log(`Blur or Enter detected for ${propertyType}, setting value: "${e.target.value}"`);
          handleTokenChange(propertyType, e.target.value);
        }
      };

      return (
        <div className="propTypeographyItem">
          <Input
            type="text"
            placeholder={propertyType}
            value={localValues[propertyType] || tokenName || inputValue}
            onChange={(e) => 
              setLocalValues((prev) => ({
                ...prev,
                [propertyType]: e.target.value,
              }))
            }
            onBlur={(e) => handleBlurOrEnter(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleTokenChange(propertyType, e.target.value);
              }
            }}
          />
          {options.length > 0 && (
            <Dropdown
              overlay={
                <Menu>
                  {options.map(({ name, value }) => (
                    <Menu.Item
                      key={name}
                      onClick={() => {
                        console.log(`Typography Menu.Item clicked: ${name} - ${value}`);
                        handleTokenChange(propertyType, value);
                      }}
                    >
                      {name} - {value}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <Button style={{ cursor: 'pointer' }}>
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </div>
      );
    }; // End of renderTypographyDropdown

    const saveChanges = async (tokens) => {
      if (!selectedComponentInfo || !selectedComponentInfo.id) {
        console.error('No component selected or componentId is undefined.');
        return;
      }

      const tokenKey = getTokenKey();
      const designTokensToSave = tokens[tokenKey]?.[viewportSize] || {};
      //const componentId = selectedComponentInfo.id;
      //const designTokensToSave = tokens[componentId]?.[viewportSize] || {};
      
      try {    
        const token = localStorage.getItem('token');
        const response = await axios.put(
          'https://www.webbify.io/designTokens',
          {
            componentId: tokenKey,
            //componentId,
            viewportSize,
            designTokens: designTokensToSave,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        updateDesignTokens(tokenKey, designTokensToSave, viewportSize);

        fetchTokens();
        console.log('Tokens updated:', response.data);
      } catch (error) {
        console.error('Error updating tokens:', error.response ? error.response.data : error.message);
      }
    };

    return (
        <div className="properties-sidebar">
            <div className="properties-sidebar-bottom-btn">
                <button onClick={() => { console.log('Save button clicked'); saveChanges(editedTokens); }}>Save</button>
            </div>
            <Collapse defaultActiveKey={['position-options', 'float-options', 'display-options', 'flex-options', 'dimensions-spacing', 'color-options', 'border-options', 'typography', 'custom-css', '4']} ghost expandIcon={CustomExpandIcon} >
                {selectedComponentInfo && (
                    <>  
                        <Panel className="PositionOptionsPanel" header="Position Options" key="position-options">
                            <PositionOptions
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                designTokens={designTokens}
                                tokenNames={tokenNames}
                                handleTokenChange={handleTokenChange}
                                setEditedTokens={setEditedTokens}
                                setTokenNames={setTokenNames}
                                viewportSize={viewportSize}
                            />
                        </Panel>
                        {/* Flaot Options section */}
                        <Panel className="FloatOptionsPanel" header="Float Options" key="float-options">
                            <FloatOptions
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                designTokens={designTokens}
                                tokenNames={tokenNames}
                                handleTokenChange={handleTokenChange}
                                setEditedTokens={setEditedTokens}
                                setTokenNames={setTokenNames}
                                viewportSize={viewportSize}
                            />
                        </Panel>
                        {/* Display Options section */}
                        <Panel className="DisplayOptionsPanel" header="Display Options" key="display-options">
                            <DisplayOptions
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                designTokens={designTokens}
                                tokenNames={tokenNames}
                                handleTokenChange={handleTokenChange}
                                setEditedTokens={setEditedTokens}
                                setTokenNames={setTokenNames}
                                viewportSize={viewportSize}
                            />
                        </Panel>
                        {/* Flex Options section */}
                        <Panel className="FlexOptionsPanel" header="Flex Options" key="flex-options">
                            <FlexOptions 
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                designTokens={designTokens}
                                tokenNames={tokenNames}
                                handleTokenChange={handleTokenChange}
                                renderColorPicker={renderColorPicker}
                                setEditedTokens={setEditedTokens}
                                setTokenNames={setTokenNames}
                                viewportSize={viewportSize}
                            />
                        </Panel>
                        {/* Dimensions and spacing Options section */}
                        <Panel className="DimensionsOptionsPanel" header="Dimensions and Spacing" key="dimensions-spacing">
                            <DimensionsSpacingOptions 
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                designTokens={designTokens}
                                tokenNames={tokenNames}
                                handleTokenChange={handleTokenChange}
                                setEditedTokens={setEditedTokens}
                                setTokenNames={setTokenNames}
                                viewportSize={viewportSize}
                            />
                        </Panel>

                        {/* Color Options section */}
                        <Panel className="ColorOptionsPanel" className="colorPanel" header="Colour" key="color-options">
                            <ColorOptions 
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                designTokens={designTokens}
                                tokenNames={tokenNames}
                                handleTokenChange={handleTokenChange}
                                renderColorPicker={renderColorPicker}
                                setEditedTokens={setEditedTokens}
                                setTokenNames={setTokenNames}
                                viewportSize={viewportSize}
                            />
                        </Panel>

                        {/* Border Options section */}
                        <Panel className="BorderOptionsPanel" header="Border Options" key="border-options">
                            <BorderOptions
                              selectedComponentInfo={selectedComponentInfo}
                              globalTokens={globalTokens}
                              editedTokens={editedTokens}
                              designTokens={designTokens}
                              tokenNames={tokenNames}
                              handleTokenChange={handleTokenChange}
                              setEditedTokens={setEditedTokens}
                              setTokenNames={setTokenNames}
                              viewportSize={viewportSize}
                            />
                        </Panel>

                        {/* Typography Options section */}
                        <Panel className="TypographyOptionsPanel" className="propTypePanel" header="Typography" key="typography">
                          <Row gutter={[8, 8]}>
                            <Col span={24}>
                              <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                                <label alt="font-family">
                                  <TypeFontFamily />
                                </label>
                                {renderTypographyDropdown('fontFamily')}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={[8, 8]}>
                            <Col span={24}>
                              <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                                <label alt="font-weight">
                                  <TypeFontWeight />
                                </label>
                                {renderTypographyDropdown('fontWeight')}
                              </div>
                            </Col>
                          </Row>
                          <Row className="propTypeRow2" gutter={[8, 8]}>
                            <Col span={12}>
                              <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                                <label alt="font-size">
                                  <TypeFontSize />
                                </label>
                                {renderTypographyDropdown('fontSize')}
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                                <label alt="line-height">
                                  <TypeLineHeight />
                                </label>
                                {renderTypographyDropdown('lineHeight')}
                              </div>
                            </Col>
                          </Row>
                        </Panel>
                        {/* Custom CSS section */}
                        <Panel className="CustomCssOptionsPanel" header="Custom CSS" key="custom-css">
                          <CustomCSSPanel
                            selectedComponentInfo={selectedComponentInfo}
                            editedTokens={editedTokens}
                            designTokens={designTokens}
                            viewportSize={viewportSize}
                            handleTokenChange={handleTokenChange}
                            setEditedTokens={setEditedTokens}
                            setTokenNames={setTokenNames}
                            tokenNames={tokenNames}
                            updateDesignTokens={updateDesignTokens}
                          />
                        </Panel>
                    </>
                )}
                {!selectedComponentInfo && (
                    <StaticContentPanel 
                        handleTokenChange={handleTokenChange}
                        editedTokens={editedTokens}
                        setEditedTokens={setEditedTokens}
                        selectedComponentInfo={selectedComponentInfo}
                        globalTokens={globalTokens}
                        handleDimensionChange={handleTokenChange}
                        tokenNames={tokenNames}
                        renderColorPicker={renderColorPicker}
                        renderTypographyDropdown={renderTypographyDropdown}
                    />
                )}
            </Collapse>
        </div>
    );
};

export default PropertiesSidebar;
