// frontend/src/components/ComponentCodeImports.js
const codePath = 'https://www.webbify.io/ui_components/';

export const componentCodeImports = {
  Banner: () =>
    fetch(`${codePath}Banner/Banner.jsx`).then((res) => res.text()),
  Card: () =>
    fetch(`${codePath}Card/Card.jsx`).then((res) => res.text()),
  Carousel: () =>
    fetch(`${codePath}Carousel/Carousel.jsx`).then((res) => res.text()),
  Checkbox: () =>
    fetch(`${codePath}Checkbox/Checkbox.jsx`).then((res) => res.text()),
  Container: () =>
    fetch(`${codePath}Container/Container.jsx`).then((res) => res.text()),
  Divider: () =>
    fetch(`${codePath}Divider/Divider.jsx`).then((res) => res.text()),
  IconButton: () =>
    fetch(`${codePath}Button/IconButton.jsx`).then((res) => res.text()),
  Image: () =>
    fetch(`${codePath}Image/Image.jsx`).then((res) => res.text()),
  Link: () =>
    fetch(`${codePath}Link/Link.jsx`).then((res) => res.text()),
  List: () =>
    fetch(`${codePath}List/List.jsx`).then((res) => res.text()),
  MainHero: () =>
    fetch(`${codePath}MainHero/MainHero.jsx`).then((res) => res.text()),
  NotificationBanner: () =>
    fetch(`${codePath}NotificationBanner/NotificationBanner.jsx`).then((res) =>
      res.text()
    ),
  PrimaryButton: () =>
    fetch(`${codePath}Button/PrimaryButton.jsx`).then((res) => res.text()),
  RadioButton: () =>
    fetch(`${codePath}RadioButton/RadioButton.jsx`).then((res) => res.text()),
  SecondaryButton: () =>
    fetch(`${codePath}Button/SecondaryButton.jsx`).then((res) => res.text()),
  TertiaryButton: () =>
    fetch(`${codePath}Button/TertiaryButton.jsx`).then((res) => res.text()),
  Text: () =>
    fetch(`${codePath}Text/Text.jsx`).then((res) => res.text()),
  TextArea: () =>
    fetch(`${codePath}TextArea/TextArea.jsx`).then((res) => res.text()),
  TextInput: () =>
    fetch(`${codePath}TextInput/TextInput.jsx`).then((res) => res.text()),
};