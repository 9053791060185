// frontend/src/utils/templates/NewComponentTemplate.js
const defaultComponentTemplate = `
(function() {
  const { useState, useEffect } = window.React;
  const useComponentClick = window.useComponentClick || (() => () => {
  });
  const DEFAULT_CHARACTER_LIMIT = 256;
  const TextBox = (props) => {
    const {
      id,
      tokens = {},
      initialValue = "",
      onChange = () => {
      },
      characterLimitEnabled = false,
      seoTitle = "",
      seoDescription = "",
      ariaLabel = "text box",
      isBuilder = true
    } = props;
    const [value, setValue] = useState(initialValue);
    const handleComponentClick = useComponentClick();
    useEffect(() => {
    }, [tokens]);
    useEffect(() => {
      setValue(initialValue);
    }, []);
    const defaultTokens = {
      width: "200px",
      minWidth: "100px",
      height: "30px",
      backgroundColor: "#ffffff",
      color: "#000000",
      fontSize: "14px",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px"
    };
    const mergedTokens = { ...defaultTokens, ...tokens };
    const handleInputChange = (e) => {
      let newValue = e.target.value;
      if (characterLimitEnabled && newValue.length > DEFAULT_CHARACTER_LIMIT) {
        newValue = newValue.slice(0, DEFAULT_CHARACTER_LIMIT);
      }
      setValue(newValue);
      onChange(newValue);
    };
    const handleFocus = () => {
    };
    const handleBlur = () => {
    };
    return /* @__PURE__ */ React.createElement(
      "input",
      {
        id,
        type: "text",
        style: { ...mergedTokens },
        value,
        onChange: handleInputChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onClick: (e) => {
          e.stopPropagation();
          if (isBuilder) {
            handleComponentClick({ id, name: "TextBox", tokens: mergedTokens, props });
          }
        },
        tabIndex: "0",
        maxLength: characterLimitEnabled ? DEFAULT_CHARACTER_LIMIT : void 0,
        "aria-label": ariaLabel,
        "data-seo-title": seoTitle,
        "data-seo-description": seoDescription
      }
    );
  };
  TextBox.defaultProps = {
    initialValue: "",
    onChange: () => {
    },
    tokens: {},
    characterLimitEnabled: false,
    seoTitle: "",
    seoDescription: "",
    ariaLabel: "text box",
    isBuilder: true
  };
  window.ComponentModule = { default: TextBox };
})();
`;
export default defaultComponentTemplate;