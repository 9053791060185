// frontend/src/pages/UiBuilder.js
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import '../ui_builder.css';
import { AppstoreOutlined, FolderOutlined } from '@ant-design/icons';
import { processVoiceCommand as processCommand, normalizeComponentName } from '../utils/voiceRecognition';
import { components as componentImports } from '../components/ComponentImports';
import { generateUniquePageId, verifyUniquePageIds } from '../utils/pageUtils';
import usePageManagement from '../hooks/usePageManagement';
import { createNewPage } from '../utils/pageCreationUtils';
import { fetchPageContent } from '../utils/fetchPageContentUtils';
import { usePageContentUtils } from '../hooks/usePageContentUtils';
import { useDesignTokenManagement } from '../hooks/useDesignTokenManagement';
import { reconstructFrontendStructureFromBackendSchema } from '../utils/reconstructFrontendStructureFromBackendSchema';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { ReactComponent as DragIcon } from '../assets/icons/drag-icon.svg'; 
import { ReactComponent as JiraIcon } from '../assets/icons/jira-icon.svg'; 

import Header from '../components/Header';
import ComponentSidebar from '../components/ComponentSidebar';
import PageContainer from '../components/PageContainer';
import PropertiesSidebar from '../components/PropertiesSidebar';
import MyJiraTicketSidebar from '../components/MyJiraTicketSidebar';
import UiBuilderControls from '../components/uiBuilderControls';
import TabbedInterface from '../components/TabbedInterface';
import PagesSidebar from '../components/pagesSidebar';
import TemplatesSidebar from '../components/TemplatesSidebar';
import { components } from '../components/ComponentImports';
import { TokensContext } from '../contexts/TokensContext';
import SelectionPanel from '../components/SelectionPanel';
import { PageContentContext } from '../contexts/PageContentContext';
import { ComponentPropsContext } from '../contexts/ComponentPropsContext';
import { addComponentToColumn } from '../utils/rowColumnManager';
import { getComponentProps } from '../hooks/getComponentProps';
import useComponentDrop from '../hooks/useComponentDrop';
import useSavePageContent from '../hooks/useSavePageContent';
import useSavePageContainer from '../hooks/useSavePageContainer';
import GitHubRepoViewer from '../components/gitHub/GitHubRepoViewer';
import { ViewportContext } from '../contexts/ViewportContext';
import { BuilderContext } from '../contexts/BuilderContext';
import useComponentClick from '../hooks/useComponentClick';
import { SelectedComponentContext } from '../contexts/SelectedComponentContext';
import FeedbackPopup from '../components/FeedbackPopup';
import { TemplateProvider } from '../contexts/TemplateContext';

const UiBuilder = ({ isLoggedIn, setIsLoggedIn }) => {
  const [isListening, setIsListening] = useState(false);
  const { pageContent, setPageContent, rows, setRows } = useContext(PageContentContext);
  const { selectedComponentInfo, setSelectedComponentInfo } = useContext(SelectedComponentContext);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const { selectedComponentProps } = useContext(ComponentPropsContext);
  const [manualSaveRequested, setManualSaveRequested] = useState(false);
  const { nestedComponents, setNestedComponents } = useContext(NestedComponentsContext);
  const { savePageContainer } = useSavePageContainer();
  const [viewType, setViewType] = useState('pages');
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [rightSidebarView, setRightSidebarView] = useState('properties');
  const handleComponentClick = useComponentClick();

  // Set viewport for adjusting
  const [viewportSize, setViewportSize] = useState('large');

  // Retrieve the token from local storage
  const token = localStorage.getItem('token') || '';

  // Retrieve the userId from the token
  const userId = localStorage.getItem('userId') || '';

  const { 
    designTokens,
    editedTokens,
    globalTokens,
    fetchTokens,
    handleTokenChange,
    updateDesignTokens,
    setEditedTokens,
  } = useDesignTokenManagement(token);

  const [newPageName, setNewPageName] = useState('');

  // hook for usePageManagement
  const { pages, setPages, activePage, setActivePage } = usePageManagement(
    components, 
    globalTokens,
    designTokens, 
    editedTokens, 
    selectedComponentInfo, 
    setSelectedComponentInfo,
    token,
  );


  const handleRemoveComponent = (componentId) => {
    console.log(`Attempting to remove component with ID: ${componentId}`);

    // Remove from rows structure
    const updatedRows = rows.map((row) => ({
      ...row,
      components: row.components.map((columnComponents) => {
        if (Array.isArray(columnComponents)) {
          return columnComponents.filter((comp) => comp.id !== componentId);
        }
        console.warn("Non-array element in components:", columnComponents);
        return columnComponents; // Leave as is if it's not an array
      }),
    }));

    // Remove from nestedComponents, including any nested components of this component
    const updatedNestedComponents = { ...nestedComponents };

    // If the component being removed is a parent (e.g., a Container), remove all its nested components
    if (updatedNestedComponents[componentId]) {
      delete updatedNestedComponents[componentId];
      console.log(`Removed nested components for parent ID: ${componentId}`);
    }

    // Iterate over all parents to remove the component from nested structures
    Object.keys(updatedNestedComponents).forEach((parentId) => {
      const parentNestedComponentsMap = updatedNestedComponents[parentId];

      if (typeof parentNestedComponentsMap === 'object' && parentNestedComponentsMap !== null) {
        // Iterate over each columnId within the parent
        Object.keys(parentNestedComponentsMap).forEach((columnId) => {
          const componentsArray = parentNestedComponentsMap[columnId];
          if (Array.isArray(componentsArray)) {
            const filteredComponents = componentsArray.filter(
              (nestedComp) => nestedComp.id !== componentId
            );

            if (filteredComponents.length !== componentsArray.length) {
              updatedNestedComponents[parentId][columnId] = filteredComponents;
              console.log(
                `Removed nested component with ID ${componentId} from parent ID ${parentId}, column ID ${columnId}`
              );
            }

            // Clean up empty arrays
            if (updatedNestedComponents[parentId][columnId].length === 0) {
              delete updatedNestedComponents[parentId][columnId];
              console.log(
                `Removed empty components array for parent ID: ${parentId}, column ID: ${columnId}`
              );
            }
          } else {
            console.warn(
              `Non-array nested components for parentId: ${parentId}, columnId: ${columnId}`
            );
          }
        });

        // After processing all columns, if the parent has no columns left, remove the parent
        const parentHasNoColumns =
          Object.keys(updatedNestedComponents[parentId]).length === 0;
        if (parentHasNoColumns) {
          delete updatedNestedComponents[parentId];
          console.log(
            `Removed parent ID ${parentId} from nestedComponents as it has no more nested components`
          );
        }
      } else {
        console.warn("Non-object nested components for parentId:", parentId);
      }
    });

    // Debug logs
    console.log("Updated rows after removal:", updatedRows);
    console.log("Updated nested components after removal:", updatedNestedComponents);

    // Update state
    setRows(updatedRows);
    setNestedComponents(updatedNestedComponents);

    // Clear selection if the removed component was selected
    if (selectedComponentInfo?.id === componentId) {
      setSelectedComponentInfo(null);
      console.log(
        `Selected component with ID ${componentId} has been removed and selection cleared.`
      );
    }

    console.log(`Component with ID ${componentId} has been successfully removed.`);
  };


  useEffect(() => {
    console.log('UiBuilder: fetchAndSetPages useEffect triggered');
    console.log('Dependencies:', {
      token,
      components,
      designTokens,
      selectedComponentInfo,
      editedTokens,
      pagesLength: pages.length,
    });

    const fetchAndSetPages = async () => {
      if (token) {
        try {
          // Fetch the page content only if there is no existing content (i.e., on the initial load)
          if (pages.length === 0) {
            const fetchedPages = await fetchPageContent(token, components, designTokens, selectedComponentInfo, setSelectedComponentInfo, editedTokens, setEditedTokens);
            if (fetchedPages) {
              const validPages = fetchedPages.map(page => ({
                ...page,
                pageName: page.pageName || 'Unnamed Page',
              }));
              setPages(validPages);
              if (validPages.length > 0) {
                setActivePage(validPages[0].pageId);
              }
            } else {
              console.log('No pages found. Creating a new page for the user.');

              // If no pages are found, create a new page
              const newPageName = 'yourFirstPage';
              const newPage = createNewPage(
                newPageName,
                components,
                designTokens,
                rows,
                pages,
                editedTokens,
                setEditedTokens,
                setPages,
                setActivePage
              );

              if (newPage) {
                console.log('New page created:', newPage);
                savePageContent(newPage.pageId, newPage); // Save the newly created page
              }
            }
          }
        } catch (error) {
          console.error('Error fetching page content:', error);
        }
      }
    };

    fetchAndSetPages();
  }, [token, components, designTokens, selectedComponentInfo, setSelectedComponentInfo, editedTokens, setEditedTokens, pages.length, setPages, setActivePage]);
  // Comments:
  // - Runs on the initial load and when any dependency changes.
  // - Ensures pages are fetched only if none exist.

  // hooks for convertFrontendStructureToBackendSchema, convertPageContentToJSON in usePageContentUtils
  const { convertFrontendStructureToBackendSchema } = usePageContentUtils(components, selectedComponentProps);

  const savePageContent = useSavePageContent(
    token, 
    pages, 
    setPages, 
    activePage, 
    components, 
    designTokens, 
    selectedComponentInfo, 
    setSelectedComponentInfo, 
    editedTokens, 
    setEditedTokens,
    selectedComponentProps
    //nestedComponents
  );

  // Auto-save functionality
  useEffect(() => {
    console.log('UiBuilder: Auto-save useEffect triggered');
    console.log('Dependencies:', { activePage, pages });

    const autoSave = setInterval(() => {
      if (activePage) {
        console.log(`Auto-saving page: ${activePage}`);
        const pageToSave = pages.find(page => page.pageId === activePage);
        if (pageToSave) {
          savePageContent(activePage, pageToSave);
        } else {
          console.warn('No active page to save.');
        }
      }
    }, 60000); // Save every 60 seconds.

    return () => clearInterval(autoSave);
  }, [activePage, pages, savePageContent]);
  // Comments:
  // - Periodically auto-saves the active page every 60 seconds.
  // - Cleanup ensures no memory leaks when the component unmounts.

  const [openTabs, setOpenTabs] = useState([]);

  // Synchronize activePage with openTabs**
  useEffect(() => {
    console.log('UiBuilder: Sync activePage with openTabs useEffect triggered');
    console.log('Dependencies:', { activePage, openTabs });

    if (activePage && !openTabs.includes(activePage)) {
      console.log(`Adding activePage (${activePage}) to openTabs.`);
      setOpenTabs(prevTabs => [...prevTabs, activePage]);
    }
  }, [activePage, openTabs]);
  // Comments:
// - Ensures the active page is included in the open tabs list.
// - Adds the active page to openTabs only if it's not already included.

  // Viewport change functions
  const handleViewportChange = (size) => {
    setViewportSize(size);
  };

  const handleSavePageContainer = async () => {
    if (token && userId) {
      const pageContent = {
        rows,
        designTokens,
        editedTokens,
        nestedComponents,
        selectedComponentInfo,
      };

      const filename = `${activePage}.json`; // Use activePage ID as filename

      const url = await savePageContainer(token, userId, filename, pageContent);
      if (url) {
        //console.log('PageContainer content saved at:', url);
      }
    }
  };

  // Call handleSavePageContainer when needed, e.g., on manual save
  const handleManualPageContainerSave = async () => {
    await handleSavePageContainer(); // Save the page container first
    setManualSaveRequested(true); // Set the flag to re-fetch the pages from the backend after saving
  };


  const handleCreateNewPage = (newPageName) => {
    const newPage = createNewPage(
      newPageName, 
      components, 
      designTokens, 
      rows, 
      pages, 
      editedTokens, 
      setEditedTokens, 
      setPages,
      setActivePage
    );

    // Save the newly created page
    if (newPage) {
      savePageContent(newPage.pageId, newPage);
    }
  };

  const duplicatePage = async (pageId) => {
    const token = localStorage.getItem('token');
    const pageToDuplicate = pages.find((page) => page.pageId === pageId);

    if (!pageToDuplicate) {
      console.error('Page to duplicate not found');
      return;
    }

    const newPageId = generateUniquePageId(); // Generate a new unique ID for the duplicated page

    // Deep clone the rows and nestedComponents
    const originalRows = JSON.parse(JSON.stringify(pageToDuplicate.rows || []));
    const originalNestedComponents = JSON.parse(JSON.stringify(pageToDuplicate.nestedComponents || {}));

    // Generate new unique IDs for components and nested components
    const componentIdMap = {};

    // Function to generate new IDs and update component IDs
    const generateNewIds = (componentsArray) => {
      componentsArray.forEach((component) => {
        const oldComponentId = component.id;
        const newComponentId = generateUniquePageId();
        component.id = newComponentId;
        componentIdMap[oldComponentId] = newComponentId;

        // Update nestedComponents if any
        if (component.nestedComponents && Array.isArray(component.nestedComponents)) {
          component.nestedComponents.forEach((nestedComponentGroup) => {
            nestedComponentGroup.components.forEach((nestedComponent) => {
              const oldNestedComponentId = nestedComponent.id;
              const newNestedComponentId = generateUniquePageId();
              nestedComponent.id = newNestedComponentId;
              componentIdMap[oldNestedComponentId] = newNestedComponentId;
            });
          });
        }
      });
    };

    originalRows.forEach((row) => {
      row.columns.forEach((column) => {
        if (Array.isArray(column.components)) {
          generateNewIds(column.components);
        }
      });
    });

    // Update nestedComponents with new IDs
    const updatedNestedComponents = {};
    for (const [oldComponentId, nestedComponentsForComponent] of Object.entries(originalNestedComponents)) {
      const newComponentId = componentIdMap[oldComponentId];
      if (newComponentId) {
        updatedNestedComponents[newComponentId] = nestedComponentsForComponent;
        // Update IDs within nested components
        for (const nestedComponentGroup of nestedComponentsForComponent) {
          for (const nestedComponent of nestedComponentGroup.components) {
            const oldNestedComponentId = nestedComponent.id;
            const newNestedComponentId = componentIdMap[oldNestedComponentId];
            if (newNestedComponentId) {
              nestedComponent.id = newNestedComponentId;
            }
          }
        }
      }
    }

    // Use convertFrontendStructureToBackendSchema to prepare data
    const pageContentForBackend = convertFrontendStructureToBackendSchema(originalRows, updatedNestedComponents);

    const duplicatedPageData = {
      pageId: newPageId,
      pageName: `${pageToDuplicate.pageName}-copy`,
      content: { rows: pageContentForBackend },
    };

    try {
      // Send duplicated page to the backend
      await axios.put('https://www.webbify.io/pageContent', duplicatedPageData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Reconstruct the content React component
      const { rows: pageRows, nestedComponents } = await reconstructFrontendStructureFromBackendSchema(
        duplicatedPageData.content.rows,
        components,
        null,
        token
      );

      const newPageContent = (
        <PageContainer
          components={components}
          designTokens={designTokens}
          rows={pageRows}
          editedTokens={editedTokens}
          setEditedTokens={setEditedTokens}
          nestedComponents={nestedComponents}
        />
      );

      // Update the pages state
      const newPage = {
        pageId: newPageId,
        pageName: `${pageToDuplicate.pageName}-copy`,
        rows: pageRows,
        nestedComponents: nestedComponents,
        content: newPageContent,
      };

      setPages((prevPages) => [...prevPages, newPage]);
    } catch (error) {
      console.error('Error duplicating page:', error.response?.data || error.message);
    }
  };


  const handleRemovePage = async (pageId) => {
    if (pages.length <= 1) {
      alert("You can't remove the last page.");
      return;
    }

    try {
      // Send delete request to backend
      await axios.delete(`https://www.webbify.io/pageContent/${pageId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Update the pages state after successful deletion
      const updatedPages = pages.filter((page) => page.pageId !== pageId);
      setPages(updatedPages);

      // If the currently selected page is removed, select the first page as active
      if (activePage === pageId) {
        setActivePage(updatedPages[0]?.pageId || null);
      }

      console.log(`Page ${pageId} deleted successfully.`);
    } catch (error) {
      console.error('Error deleting page:', error.response?.data || error.message);
      alert('Failed to delete the page. Please try again.');
    }
  };

  const handleRenamePage = async (pageIndex, newName) => {
      let updatedPages = [...pages];
      updatedPages[pageIndex].pageName = newName;
      setPages(updatedPages);

      // Save the updated content to backend
      await savePageContent(updatedPages[pageIndex].pageId);
  };

  const handleSelectPage = (pageId) => {
      if (!openTabs.includes(pageId)) {
          setOpenTabs([...openTabs, pageId]);
      }
      setActivePage(pageId);
      const selectedPage = pages.find(page => page.pageId === pageId);
      if (selectedPage && selectedPage.content && selectedPage.content.props) {
          setRows(selectedPage.content.props.rows); // Update rows on page switch
      }
  };

  const handleCloseTab = (pageId) => {
      const newOpenTabs = openTabs.filter(id => id !== pageId);
      setOpenTabs(newOpenTabs);
      // If the closed tab is the active one, switch to another tab (e.g., the first in the new array)
      if (activePage === pageId && newOpenTabs.length > 0) {
          setActivePage(newOpenTabs[0]);
      }
  };

  const handleManualSave = () => {
    // Increment triggerManualSave to trigger the effect
    setManualSaveRequested(true);
  };

  useEffect(() => {
    if (manualSaveRequested) {
      const pageToSave = pages.find(page => page.pageId === activePage);
      if (pageToSave) {
        savePageContent(activePage, pageToSave)
          .then(() => setManualSaveRequested(false)) // Reset the flag after save
          .catch((error) => {
            console.error('Error saving page:', error);
            setManualSaveRequested(false); // Reset the flag if there's an error
          });
      } else {
        console.error('No active page to save');
        setManualSaveRequested(false); // Reset the flag if there's no page to save
      }
    }
  }, [manualSaveRequested, activePage, pages, savePageContent]);

  // Determine which sidebar to show based on viewType
  const showComponentSidebar = viewType === 'component';

  const handleSwitchSidebar = (sidebar) => {
    setViewType(sidebar);
  };

  const handleRepoSelect = (repoName) => {
    if (repoName === 'pages') {
      setViewType('pages');
      setSelectedRepo(null);
    } else {
      setViewType('github');
      setSelectedRepo(repoName);
    }
  };

  const handleComponentDragStart = (event, componentName) => {
      event.dataTransfer.setData('componentName', componentName);
      event.dataTransfer.setData('designTokens', JSON.stringify(designTokens[componentName]));
      //console.log('Component being dragged:', componentName);
  };

  // hook for useComponentDrop
  const handleComponentDrop = useComponentDrop(components, designTokens);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const normalizeComponentName = (name) => {
    if (!name) return null;

    console.log('Raw input:', name);

    const formattedName = name
      .replace(/\bcomponent\b/i, '')
      .trim()
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');

    console.log('Normalized name:', formattedName);

    if (components[formattedName]) {
      return formattedName;
    }

    console.warn(`Component '${formattedName}' not found.`);
    return null;
  };

  const executeAction = async (action) => {
    console.log('Executing action:', action);

    // Handle 'save' action
    if (action.save) {
      handleManualSave();
      alert('Page saved successfully.');
      return;
    }

    // Handle 'createRow' action
    if (action.createRow) {
      const columnCount = action.columnCount || 1;
      const newRow = {
        columns: new Array(columnCount).fill(1),
        components: new Array(columnCount).fill([]),
      };
      setRows((prevRows) => [...prevRows, newRow]);
      alert(`New row created with ${columnCount} column(s).`);
      return;
    }

    // Handle 'select' action
    if (action.type === 'select' && action.selectComponent) {
      const componentName = capitalizeFirstLetter(action.componentName);

      if (!componentName) {
        alert('No component name provided.');
        return;
      }

      let targetRowIndex = rows.length - 1; // Default to the last row
      let targetColumnIndex = 0; // Default to the first column

      // Determine the row index
      if (action.rowIndex !== null && action.rowIndex !== undefined) {
        if (action.rowIndex >= 0 && action.rowIndex < rows.length) {
          targetRowIndex = action.rowIndex;
        } else {
          alert(`Row ${action.rowIndex + 1} does not exist.`);
          return;
        }
      }

      // Determine the column index
      if (action.columnIndex !== null && action.columnIndex !== undefined) {
        const targetRow = rows[targetRowIndex];
        if (action.columnIndex >= 0 && action.columnIndex < targetRow.columns.length) {
          targetColumnIndex = action.columnIndex;
        } else {
          alert(`Column ${action.columnIndex + 1} does not exist in row ${targetRowIndex + 1}.`);
          return;
        }
      }

      const targetComponents = rows[targetRowIndex]?.components[targetColumnIndex] || [];
      const targetComponent = targetComponents.find(
        (comp) => normalizeComponentName(comp.name) === componentName
      );

      if (!targetComponent) {
        alert(
          `Component '${componentName}' not found in row ${
            targetRowIndex + 1
          }, column ${targetColumnIndex + 1}.`
        );
        return;
      }

      console.log(
        `Selecting component '${componentName}' in row ${
          targetRowIndex + 1
        }, column ${targetColumnIndex + 1}.`
      );

      // Use the `handleComponentClick` hook
      handleComponentClick(targetComponent);

      alert(
        `Component '${componentName}' selected in row ${
          targetRowIndex + 1
        }, column ${targetColumnIndex + 1}.`
      );
      return;
    }

    // Handle 'add component' action
    if (action.type === 'add' && action.componentName) {
      const componentName = capitalizeFirstLetter(action.componentName);

      if (!components[componentName]) {
        alert(`Component '${componentName}' not found.`);
        return;
      }

      let targetRowIndex = rows.length - 1; // Default to the last row
      let targetColumnIndex = 0; // Default to the first column

      // Determine the row index
      if (action.rowIndex !== null && action.rowIndex !== undefined) {
        if (action.rowIndex >= 0 && action.rowIndex < rows.length) {
          targetRowIndex = action.rowIndex;
        } else {
          alert(`Row ${action.rowIndex + 1} does not exist.`);
          return;
        }
      }

      // Determine the column index
      if (action.columnIndex !== null && action.columnIndex !== undefined) {
        const targetRow = rows[targetRowIndex];
        if (action.columnIndex >= 0 && action.columnIndex < targetRow.columns.length) {
          targetColumnIndex = action.columnIndex;
        } else {
          alert(`Column ${action.columnIndex + 1} does not exist in row ${targetRowIndex + 1}.`);
          return;
        }
      }

      // Simulate the drag-and-drop action
      const dragEvent = {
        preventDefault: () => {}, // Mock preventDefault
        dataTransfer: {
          getData: (type) => {
            if (type === 'text/plain') {
              return JSON.stringify({
                componentSource: 'local',
                componentName,
                designTokens: designTokens[componentName] || {},
              });
            }
            return null;
          },
        },
        // Mock target with a 'closest' method
        target: {
          closest: () => null,
        },
      };

      console.log(
        `Simulating drop for component: ${componentName} in row ${targetRowIndex + 1}, column ${targetColumnIndex + 1}`
      );
      await handleComponentDrop(dragEvent, targetRowIndex, targetColumnIndex);
      alert(`Component '${componentName}' added to row ${targetRowIndex + 1}, column ${targetColumnIndex + 1}.`);
      return;
    }

    console.warn('Unhandled action:', action);
  };

  const processVoiceCommand = (command) => {
    processCommand(command, executeAction);
  };

  useEffect(() => {
    console.log('UiBuilder: designTokens updated:', designTokens);
  }, [designTokens]);

  useEffect(() => {
    console.log('UiBuilder: globalTokens updated:', globalTokens);
  }, [globalTokens]);
  
  useEffect(() => {
      //console.log('UiBuilder - editedTokens updated:', editedTokens);  // Log editedTokens
  }, [editedTokens]);

  useEffect(() => {
      //console.log('selectedComponentInfo updated:', selectedComponentInfo);
  }, [selectedComponentInfo]);

  return (
    <BuilderContext.Provider value={true}>
      <ViewportContext.Provider value={viewportSize}>
        <TemplateProvider>
          <div className="builder-wrap">
            <Header className="mini-main-header" isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            <div className="ui-builder-container">
              <div className="vEditLeftSidebar">
                <div className="LeftSideBarTopControls">
                  {viewType === 'github' ? (
                    <button onClick={() => handleSwitchSidebar('github')} className={!showComponentSidebar ? 'active' : ''}>
                      Git Files
                    </button>
                  ) : (
                    <button onClick={() => handleSwitchSidebar('pages')} className={!showComponentSidebar ? 'active' : ''}>
                      Pages
                    </button>
                  )}
                  <button
                    onClick={() => handleSwitchSidebar('component')}
                    className={showComponentSidebar ? 'active' : ''}
                  >
                    Components
                  </button>
                  <button
                    onClick={() => handleSwitchSidebar('templates')}
                    className={viewType === 'templates' ? 'active' : ''}
                  >
                    Templates
                  </button>
                </div>
                {showComponentSidebar ? (
                  <ComponentSidebar 
                    onDragStart={handleComponentDragStart} 
                    designTokens={designTokens} 
                    token={token}
                    userId={userId}
                  />
                ) : viewType === 'pages' ? (
                  <PagesSidebar
                    pages={pages}
                    activePage={activePage}
                    openTabs={openTabs}
                    setOpenTabs={setOpenTabs}
                    onSelectPage={handleSelectPage}
                    onRemovePage={handleRemovePage}
                    onRenamePage={handleRenamePage}
                    onDuplicatePage={duplicatePage}
                    onAddPage={handleCreateNewPage}
                    newPageName={newPageName}
                    setNewPageName={setNewPageName}
                    setActivePage={setActivePage}
                    savePageContent={savePageContent}
                  />
                ) : viewType === 'templates' ? (
                  <TemplatesSidebar token={token} />
                ) : (
                  <GitHubRepoViewer selectedRepo={selectedRepo} />
                )}
              </div>
              <div className="pages-container">
                 <TabbedInterface 
                  pages={pages} 
                  activePage={activePage}
                  openTabs={openTabs}
                  setOpenTabs={setOpenTabs}
                  onSelectPage={handleSelectPage}
                  setActivePage={setActivePage}
                  handleCloseTab={handleCloseTab}
                  onSave={handleManualSave}
                  onSavePageContainer={handleManualPageContainerSave}
                  viewType={viewType}
                  setViewType={setViewType}
                  onRepoSelect={handleRepoSelect}
                  onViewportChange={handleViewportChange}
                  processVoiceCommand={processVoiceCommand}
                />
                {selectedComponentInfo && (
                  <SelectionPanel 
                    pages={pages}
                    onClose={() => setSelectedComponentInfo(null)}
                    onRemoveComponent={handleRemoveComponent}
                  />
                )}
              </div>
              <div className="vEditRightSidebar">
                <div className="RightSideBarTopControls">
                  <div className="DragHandler">
                    <DragIcon />
                  </div>
                  <button onClick={() => setRightSidebarView('properties')}>Style</button>
                  <button onClick={() => setRightSidebarView('jira')}><JiraIcon />Jira(5)</button>
                </div>
                {rightSidebarView === 'properties' ? (
                  <PropertiesSidebar 
                    globalTokens={globalTokens}
                    designTokens={designTokens}
                    editedTokens={editedTokens}
                    setEditedTokens={setEditedTokens}
                    fetchTokens={fetchTokens}
                    updateDesignTokens={updateDesignTokens}
                  />
                ) : (
                  <MyJiraTicketSidebar token={token} />
                )}
              </div>
              <FeedbackPopup page="UiBuilder" />
            </div>
          </div>
        </TemplateProvider>
      </ViewportContext.Provider>
    </BuilderContext.Provider>
  );
};

export default UiBuilder;
