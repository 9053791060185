// frontend/src/components/DynamicComponentLoader.js
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const BASE_URL = 'https://www.webbify.io';
const loadedComponents = {}; // Cache loaded components to avoid re-fetching

// Function to dynamically load a component from S3 (global folder)
export const loadComponentFromS3 = async (componentName, token) => {
  const componentFileName = componentName.replace(/\.[^/.]+$/, '.js');
  if (loadedComponents[componentFileName]) {
    console.log(`Component "${componentFileName}" loaded from cache.`);
    return loadedComponents[componentFileName];
  }
  try {
    console.log(`Fetching component "${componentFileName}" from S3 with token.`);
    const response = await axios.get(`${BASE_URL}/component-management/fetch/${componentFileName}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(`Response status for "${componentFileName}":`, response.status);
    console.log(`Response data for "${componentFileName}":`, response.data);
    const componentCode = response.data;
    console.log(`Component code for "${componentFileName}":`, componentCode);
    if (componentCode.trim().startsWith('<')) {
      console.error(`Fetched component "${componentFileName}" seems to be HTML instead of JS. Possible authentication issue.`);
      return null;
    }
    const blob = new Blob([componentCode], { type: 'application/javascript' });
    const blobUrl = URL.createObjectURL(blob);
    console.log(`Importing component "${componentFileName}" from blob URL.`);
    const script = document.createElement('script');
    script.src = blobUrl;
    document.body.appendChild(script);
    await new Promise((resolve, reject) => {
      script.onload = () => {
        console.log(`Component "${componentFileName}" script loaded.`);
        resolve();
      };
      script.onerror = (err) => {
        console.error(`Error loading script for component "${componentFileName}":`, err);
        reject(err);
      };
    });
    const Component = window.ComponentModule?.default || window.ComponentModule;
    if (!Component) {
      console.error(`Component "${componentFileName}" not found on window.ComponentModule.`);
      return null;
    }
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(script);
    delete window.ComponentModule;
    loadedComponents[componentFileName] = Component;
    return Component;
  } catch (error) {
    console.error(`Error loading component from S3: "${componentFileName}"`, error);
    return null;
  }
};

// NEW: Function to dynamically load a component from the user-specific S3 folder
export const loadUserComponentFromS3 = async (componentName, token) => {
  let componentFileName = componentName.replace(/\.[^/.]+$/, '.js');
  const userId = localStorage.getItem('userId') || '';
  // If the component name already starts with the userId folder, remove it
  if (userId && componentFileName.startsWith(userId + '/')) {
    componentFileName = componentFileName.slice(userId.length + 1);
    console.log(`Adjusted component file name: "${componentFileName}"`);
  }
  const cacheKey = `user-${componentFileName}`; // Separate cache key for user components
  if (loadedComponents[cacheKey]) {
    console.log(`User component "${componentFileName}" loaded from cache.`);
    return loadedComponents[cacheKey];
  }
  try {
    console.log(`Fetching user component "${componentFileName}" from S3 with token.`);
    const response = await axios.get(`${BASE_URL}/component-management/fetch-user/${componentFileName}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const componentCode = response.data;
    if (componentCode.trim().startsWith('<')) {
      console.error(`Fetched user component "${componentFileName}" seems to be HTML instead of JS. Possible authentication issue.`);
      return null;
    }
    const blob = new Blob([componentCode], { type: 'application/javascript' });
    const blobUrl = URL.createObjectURL(blob);
    console.log(`Importing user component "${componentFileName}" from blob URL.`);
    const script = document.createElement('script');
    script.src = blobUrl;
    document.body.appendChild(script);
    await new Promise((resolve, reject) => {
      script.onload = () => {
        console.log(`User component "${componentFileName}" script loaded.`);
        resolve();
      };
      script.onerror = (err) => {
        console.error(`Error loading script for user component "${componentFileName}":`, err);
        reject(err);
      };
    });
    const Component = window.ComponentModule?.default || window.ComponentModule;
    if (!Component) {
      console.error(`User component "${componentFileName}" not found on window.ComponentModule.`);
      return null;
    }
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(script);
    delete window.ComponentModule;
    loadedComponents[cacheKey] = Component;
    return Component;
  } catch (error) {
    console.error(`Error loading user component from S3: "${componentFileName}"`, error);
    return null;
  }
};

// Function to register a component dynamically after it has been loaded
export const registerDynamicComponent = (components, componentName, Component, designTokens = {}, source = 'local') => {
  const newComponent = {
    id: uuidv4(),
    component: Component,
    name: componentName,
    source: source, // 'local', 's3', or 's3-user'
    s3ComponentName: (source === 's3' || source === 's3-user') ? componentName : undefined,
    tokens: designTokens[componentName] || {},
    props: Component.defaultProps || {},
  };
  components[componentName] = newComponent;
  console.log(`Component "${componentName}" registered dynamically with ID: "${newComponent.id}"`);
};
