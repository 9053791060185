// frontend/src/components/properties-sidebar/StaticContentPanel.js
import React from 'react';
import { Row, Col, Button, Input, Collapse, Dropdown, Menu } from 'antd';
import { 
    JustifyContentFlexStart, JustifyContentFlexEnd, AlignItemsFlexStart, AlignItemsFlexEnd, 
    JustifyContentCenter, AlignItemsCenter, FlexWrapNowrap, FlexWrapWrap, 
    FlexDirectionRowReverse, FlexDirectionRow, FlexDirectionColumn, FlexDirectionColumnReverse,
    SizeWidth, SizeHeight, SideTop, SideRight, SideBottom, SideLeft, 
    PaddingTop, PaddingRight, PaddingBottom, PaddingLeft, 
    TypeFontFamily, TypeFontWeight, TypeFontSize, TypeLineHeight, 
    AlignItemsSpaceAround, AlignItemsSpaceBetween, AlignItemsSpaceEvenly,
    JustifyContentSpaceAround, JustifyContentSpaceBetween, JustifyContentSpaceEvenly, ColumnGap, RowGap
} from '../../assets/icons/properties-sidebar/icons';
import { DownOutlined } from '@ant-design/icons';
import CustomExpandIcon from './CustomExpandIcon';

const StaticContentPanel = () => {
    const { Panel } = Collapse;

    // Static dropdown placeholder
    const renderStaticDropdown = () => (
        <Dropdown disabled overlay={<Menu><Menu.Item>Option 1</Menu.Item><Menu.Item>Option 2</Menu.Item></Menu>}>
            <Button style={{ cursor: 'not-allowed' }}>
                <DownOutlined />
            </Button>
        </Dropdown>
    );

    return (
        <Collapse
            defaultActiveKey={['position-options', 'float-options', 'display-options', 'flex-options', 'dimensions-spacing', 'color-options', 'border-options', 'typography']}
            expandIcon={({ isActive }) => <CustomExpandIcon isActive={isActive} />}
        >
            {/* Position Options */}
            <Panel className="PositionOptionsPanel" header="Position Options" key="position-options">
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="position" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="top" />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="right" />
                        </div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="bottom" />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="left" />
                        </div>
                    </Col>

                </Row>
            </Panel>

            {/* Float Options */}
            <Panel className="FloatOptionsPanel" header="Float Options" key="float-options">
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="float" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="clear" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="zindex" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="overflow" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
            </Panel>

            {/* Display Options */}
            <Panel className="DisplayOptionsPanel" header="Display Options" key="display-options">
                <Row>
                    <Col span={24}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="display" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
            </Panel>
            {/* Flex Options section */}
            <Panel className="FlexOptionsPanel" header="Flex Options" key="flex-options">
                <Row gutter={[8, 8]}>
                    <Col span={2}><Button className="flex-button" icon={<JustifyContentFlexStart />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<JustifyContentFlexEnd />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<AlignItemsFlexStart />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<AlignItemsFlexEnd />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<JustifyContentCenter />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<AlignItemsCenter />} /></Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={2}><Button className="flex-button" icon={<FlexWrapNowrap />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexWrapWrap />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexDirectionRowReverse />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexDirectionRow />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexDirectionColumn />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexDirectionColumnReverse />} /></Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={2}><Button className="flex-button" icon={<JustifyContentSpaceBetween />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<JustifyContentSpaceAround />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<JustifyContentSpaceEvenly />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<AlignItemsSpaceBetween />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<AlignItemsSpaceAround />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<AlignItemsSpaceEvenly />} /></Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><RowGap /></label>
                            <Input type="text" placeholder="columnGap" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><ColumnGap /></label>
                            <Input type="text" placeholder="rowGap" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
            </Panel>

            {/* Dimensions and Spacing section */}
            <Panel className="DimensionsOptionsPanel" header="Dimensions and Spacing" key="dimensions-spacing">
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><SizeWidth /></label>
                            <Input type="text" placeholder="width" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><SizeHeight /></label>
                            <Input type="text" placeholder="height" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
                <label>Margins</label>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><SideTop /></label>
                            <Input type="text" placeholder="marginTop" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><SideRight /></label>
                            <Input type="text" placeholder="marginRight" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><SideBottom /></label>
                            <Input type="text" placeholder="marginBottom" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><SideLeft /></label>
                            <Input type="text" placeholder="marginLeft" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
                <label>Padding</label>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><PaddingTop /></label>
                            <Input type="text" placeholder="paddingTop" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><PaddingRight /></label>
                            <Input type="text" placeholder="paddingRight" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><PaddingBottom /></label>
                            <Input type="text" placeholder="paddingBottom" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><PaddingLeft /></label>
                            <Input type="text" placeholder="paddingLeft" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
            </Panel>

            {/* Color Options section */}
            <Panel className="colorPanel" header="Colour" key="color-options">
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }} >
                            <input className="color-picker" type="color" disabled />
                            <Input type="text" placeholder="backgroundColor" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }} >
                            <input className="color-picker" type="color" disabled />
                            <Input type="text" placeholder="color" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
            </Panel>

            {/* Border Options */}
            <Panel className="BorderOptionsPanel" header="Border Options" key="border-options">
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="borderStyle" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <Input type="text" placeholder="borderThickness" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="prop-sidebar-item prop-sidebar-item-color" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Input className="border-color-picker" type="color" disabled placeholder="Border Color" />
                            <Input type="text" placeholder="borderColor" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
            </Panel>

            {/* Typography section */}
            <Panel className="propTypePanel" header="Typography" key="typography">
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><TypeFontFamily /></label>
                            <Input type="text" placeholder="fontFamily" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><TypeFontWeight /></label>
                            <Input type="text" placeholder="fontWeight" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
                <Row className="propTypeRow2" gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><TypeFontSize /></label>
                            <Input type="text" placeholder="fontSize" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                            <label><TypeLineHeight /></label>
                            <Input type="text" placeholder="lineHeight" />
                            {renderStaticDropdown()}
                        </div>
                    </Col>
                </Row>
            </Panel>
        </Collapse>
    );
};

export default StaticContentPanel;
